@use "../utils/variables" as *;

.pfs-btn {
    // font-size: 16px;
    // text-transform: uppercase;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // box-shadow: none;
    // transition: all 0.2s ease-in-out;
    // line-height: 24px;
}
.pfs-btn-icon {
    margin-right: 15px;
}
// BTN colors
.pfs-btn-primary {
    background-color: $pfs_primary;
    color: #fff;
}
.pfs-link-btn {
    border-color: transparent;
    background-color: transparent;
    padding: 10px;
    &.pfs-btn-primary {
        color: $pfs_primary;
        &:hover,
        &:focus {
            background-color: rgba($pfs_primary, 0.1);
        }
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

.pfs-action-btn {
    background-color: $pfs_red_light;
    color: $pfs_red_dark;
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 29px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
        background-color: $pfs_red_dark;
        color: $pfs_red_light;
    }
}

.btn-radio-input {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
}
.btn-radio {
    .v-input--selection-controls__input {
        position: absolute;
        left: -9999px;
        width: 0;
        height: 0;
        visibility: hidden;
    }
    .v-label {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0;
        padding: 10px 25px;
        font-size: 14px;
        line-height: 1.4;
        font-size: 18px;
        margin-bottom: 16px;
        text-align: center;
        cursor: $pfs_primary;
        text-transform: uppercase;
        background-color: $pfs_grey_light;
        transition: all 300ms linear;
        height: 52px;
        .radio-icon {
            font-size: 30px;
        }
    }
    &.v-item--active {
        label {
            background-color: $pfs_primary;
            color: #fff;
        }
    }
}

.v-btn.v-btn-text-wrap {
    white-space: normal;
    height: auto !important;
    span.v-btn__content {
        padding-top: 15px;
        padding-bottom: 15px;
        flex: 0 0;
    }
}


.pfc-rounded-icon-button{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    box-shadow: none;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    font-size: 22px; 
}
.pfc-grey-btn{
    color:#ffff;
    background-color: $pfs_grey_middle;
    .pfc-icon-button-icon{
        color:#ffff;
    }
    &:hover,&:focus{
        background-color: $pfs_primary;
    }
}
.pfc-icon-button-icon{
    line-height: 1;
}

@use '../utils/variables';

.pfs-tip-component {
    @media(min-width: variables.$md){
        margin-bottom: 36px;

    }
    .tip-wrapper {
        color: variables.$pfs_white;
        background: variables.$pfs_red;
        padding: 36px 28px;
        max-width: 735px;
        margin: 0 auto;
        position: relative;
        
        @media(min-width: variables.$md) {
            // margin: 36px auto;
            padding: 32px 46px 24px 72px;
        }

        &::before {
            content: '';
            width: 38px;
            height: 52px;
            background-image: url(../img/_icn/light_bulb.svg);
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            margin: 0 auto 36px;
            position: relative;

            @media(min-width: variables.$md) { 
                width: 24px;
                height: 32px;
                position: absolute;
                margin: 0;
                top: 36px;
                left: 20px;
            }
        }

        h3 {
            text-transform: uppercase;
        }
    }
}
@use "../utils/variables";

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: variables.$pfs_white;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15);
    color: variables.$pfs_primary;
    cursor: pointer;
    width: 100%;
    bottom: -205px;
    transition: bottom 0.3s ease-in-out;
    z-index: 91;
    position: absolute;
    text-align: left;
    @media (min-width: variables.$md) {

        text-align: center;
    }
    @media (min-width: variables.$xl) {
        bottom: -135px;
    }
    .footer-row:first-of-type {
        height: 115px;
        @media (min-width: variables.$sm) {
            height: 90px;
        }
      }
    &.active {
        bottom: 0;

        .footer-row {
            .arrow-down {
                transform: rotate(0deg);
            }
        }
        .footer-toggle-icon {
            display: inline-block;
            transform: rotate(180deg);
        }
    }

    .coming-soon-page & {
        bottom: -95px;
        @media (min-width: variables.$xl) {
            bottom: -75px;
        }

        &.active {
            bottom: 0;
        }
    }

    .footer-row {
        width: 100%;
        border-bottom: 1px solid variables.$pfs_grey_middle;
        padding: 20px 0;

        &:nth-child(1) {
            text-align: center;
            font-size: 10px;

            @media (min-width: 375px) {
                font-size: 12px;
            }

            .coming-soon-page & {
                font-size: 10px;

                @media (min-width: 375px) {
                    font-size: 12px;
                }   
            }
        }

        &:nth-child(2) {
            ul {
                li {
                    display: block;
                    @media (min-width: variables.$xl) {
                        display: inline-block;
                    }
                }
            }
        }

        &:nth-child(3) {
            border: none;
        }

        ul {
            li {
                display: block;
                margin: 0 20px;

                @media (min-width: variables.$xl) {
                    display: inline-block;
                }
            }
        }
        .footer-toggle-box {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .footer-toggle-icon {
            color: variables.$pfs_primary ;
            font-size: 18px;
            margin-left: 20px;
        }
        .arrow-down {
            display: inline-block;
            width: 10px;
            margin-left: 2px;
            transform: rotate(180deg);
            // transition: transform .1s ease-in-out;

            path {
                stroke: variables.$pfs_primary;
            }

            @media (min-width: 375px) {
                width: 14px;
                margin-left: 10px;
            }
        }
    }
}

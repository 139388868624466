@use "sass:color";
//COLOURS
$pfs_primary: #004C97;
$pfs_primary_light: #005DB8;
$pfs_white: #FFFFFF;
$pfs_grey_lighter: #F8F8F8;
$pfs_grey_light: #EFEFEF;
$pfs_grey_middle: #848484;
$pfs_black: #101010;
$pfs_cyan: #0085CA;
$pfs_cyan_light: #00A9E0;
$pfs_green: #009639;
$pfs_green_light: #78BE20;
$pfs_red: #BC272C;
$pfs_red_light: #F6E8E8;
$pfs_red_dark: #A6181C;
$pfs_orange_dark: #E62D36;
$pfs_orange: #EE6530;
$pfs_orange_light: #F5913B;
$pfs_yellow: #F7CD5A;
$pfs_yellow_light: rgba(247, 205, 90, 0.1);

//FONTS
// $font-regular: "Gotham A", "Gotham B";
// $font-bold: "Gotham A", "Gotham B";
// $font-light: "Gotham A", "Gotham B";
$font-family: "Gotham A", "Gotham B";

// font-family: "Gotham A", "Gotham B";
//     font-style: normal;
//     font-weight: 400;

$content-width: 1260px;

//BREAKPOINTS

$sm: 640px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
$hd: 1800px;
$uw: 2200px;

@use "../utils/variables";

.pfs-simple-slider-component {
    max-width: 940px;    
    margin: 0 auto 36px;
    position: relative;

    .ss-title {        
        padding: 0 28px;

        @media(min-width: 988px) {
            padding: 0;
        }
    }

    .pfs-ss-container {   
        .slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}
        .slick-list {
            padding-top: 10px;
            .slick-slide {
                margin: 0 10px;            
            }             
        }        
    }
}
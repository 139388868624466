@use "../utils/variables" as *;

.pfs-filter-grid-component {
    margin-bottom: 40px;

    .filter-wrapper {
        .filter-button {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            span {
                margin-left: 5px;
            }
        }

        .filter-selects {
            height: 0;
            overflow: hidden;
            transition: height .1s ease-in-out;

            @media(min-width: 960px) {
                height: inherit
            }

            &.active {
                height: 220px;
                @media(min-width: 960px) {
                    height: inherit;
                }                
            }

            &--product {
                &.active {
                    height: 80px;                
                }
            }
        }

        .v-form {
            .v-select,
            .v-text-field {
                // font-family:  $font-light;
            }
        }    
    }    

    .grid-container {
        display: flex;
        margin: 0 -10px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 36px;

        // .grid-item {
        //     // margin: 10px 0;
        //     width: 100%;
        //     margin: 10px;
        //     @media (min-width: $md) {
        //     }
        // }
        .grid-item {
            position: relative;
            padding: 0 10px ;
            margin-bottom: 20px;
            width: 100%;
            // margin-bottom: 50px;
            // @media (min-width: $sm) {
            //     height: 100%;
            // }
            @media (min-width: $sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
            @media (min-width: $lg) {
             
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
            }
            @media (min-width: $xl) {
                flex: 0 0 25%;
                max-width: 25%;
            }

            &--small {
                flex: 0 0 250px;
                max-width: 250px;
                text-align: center;

                @media (min-width: $sm) {
                    flex: 0 0 250px;
                    max-width: 250px;
                }
                @media (min-width: $lg) {
                    flex: 0 0 250px;
                    max-width: 250px;
                }
                @media (min-width: $xl) {
                    flex: 0 0 250px;
                    max-width: 250px;
                }
            }
        }
    }
}

.grid-container-secondary {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    justify-content: center;
    margin-bottom: 40px;
    .grid-item {
        position: relative;
        padding: 0 10px ;
        margin-bottom: 20px;
        width: 100%;
        // margin-bottom: 50px;
        // @media (min-width: $sm) {
        //     height: 100%;
        // }
        @media (min-width: $sm) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media (min-width: $lg) {
         
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
        }
        @media (min-width: $xl) {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
    &--md-large-mb{
        .grid-item{

            margin-bottom: 60px;
            @media (min-width: $md){
                margin-bottom: 20px;
            }
        }
    }
}

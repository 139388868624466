@use "./variables";
.image-slider-arrows-box,
.product-detail-slider,
.slick-slider {
    .slick-arrow {
        // padding: 12px;
        background-color: variables.$pfs_primary;
        border-radius: 2px;
        width: 58px;
        height: 58px;

        &:hover,
        &:focus {
            background-color: variables.$pfs_primary_light;
        }

        &.slick-prev {
            left: -75px;
            &::before {
                content: "";
                background-image: url(../img/_icn/arrow_nav.svg);
                width: 24px;
                height: 24px;
                display: inline-block;
                opacity: 1;
                transform: rotate(180deg);
            }
        }

        &.slick-next {
            right: -75px;
            &::before {
                content: "";
                background-image: url(../img/_icn/arrow_nav.svg);
                width: 24px;
                height: 24px;
                display: inline-block;
                opacity: 1;
            }
        }
        &.slick-disabled{

            opacity: .7;
            pointer-events: none;
        }
    }
}

@use "../utils/variables";

.pfs-component-subpage-header {
    position: relative;
    // padding-bottom: 160px;
    padding-bottom: 120px;
    padding-top: 20px;
    margin-bottom: 36px;
    color: variables.$pfs_white;
    overflow: hidden;

    @media (min-width: variables.$md) {
        // padding-top: 165px;
        padding-top: 125px;
        min-height: auto;

        &::before {
            display: block;
            content: "";
            position: absolute;
            background-size: contain;
            // width: 270px;
            // height: 270px;
            width: 170px;
            height: 170px;
            z-index: 0;
        }
        &::after {
            display: block;
            content: "";
            position: absolute;
            background-size: contain;
            // width: 280px;
            // height: 280px;
            width: 180px;
            height: 180px;
            z-index: 0;
        }
    }

    @media (min-width: variables.$xl) {
        // min-height: 900px;
        // min-height: 780px;
    }
    .pfs-breadcrumbs {
        ul {
            li {
                // &::after {
                //     color: #fff;
                // }
                // a {
                //     color: #fff;
                // }
                // span {
                //     color: #fff;
                // }
            }
        }
    }
    &--red {
        background-image: url(../img/sh_red_decoration_main.png);
        background-position: top left;
        background-color: variables.$pfs_red;
        background-size: 300px;

        @media (min-width: variables.$lg) {
            background-size: auto;
        }

        &::before {
            top: 40px;
            left: 50%;
            background-image: url(../img/sh_red_decoration_ellipse.svg);
            display: none;
        }
        &::after {
            bottom: 60px;
            left: 60%;
            background-image: url(../img/sh_red_decoration_cross.svg);
            display: none;
        }
    }
    &--blue {
        background-image: url(../img/sh_blue_decoration_main.png);
        background-position: top left;
        background-color: variables.$pfs_cyan_light;
        background-size: 300px;

        @media (min-width: variables.$lg) {
            background-size: auto;
        }

        &::before {
            bottom: 70px;
            left: 40%;
            background-image: url(../img/sh_blue_decoration_ellipse.svg);
            display: none;
        }
        &::after {
            top: 100px;
            right: 15%;
            background-image: url(../img/sh_blue_decoration_cross.svg);
            display: none;
        }
    }
    &--green {
        background-image: url(../img/sh_green_decoration_main.png);
        background-position: top left;
        background-color: variables.$pfs_green_light;
        background-size: 300px;

        @media (min-width: variables.$lg) {
            background-size: auto;
        }

        &::before {
            top: 40px;
            left: 50%;
            background-image: url(../img/sh_green_decoration_ellipse.svg);
            display: none;
        }
        &::after {
            bottom: -70px;
            right: 20%;
            background-image: url(../img/sh_green_decoration_cross.svg);
            display: none;
        }
    }
    &--yellow {
        background-image: url(../img/sh_yellow_decoration_main.png);
        background-position: top right;
        background-color: variables.$pfs_yellow;
        background-size: 300px;

        @media (min-width: variables.$lg) {
            background-size: auto;
            background-position: center right;
        }

        &::before {
            bottom: -140px;
            left: 30px;
            background-image: url(../img/sh_yellow_decoration_ellipse.svg);
            display: none;
        }
        &::after {
            top: -60px;
            left: 20%;
            background-image: url(../img/sh_yellow_decoration_cross.svg);
            display: none;
        }
    }

    .pfs-container {
        display: flex;
        flex-direction: column;
        align-items: top;
        position: relative;

        @media (min-width: variables.$md) {
            flex-direction: row;
        }

        .image-wrapper {
            flex: 1 1 50%;
            padding-right: 0;
            z-index: 1;
            margin-bottom: 10px;
            // max-width: 500px;
            // margin: 0 auto;
            position: relative;

            @media (min-width: variables.$md) {
                padding-right: 20px;
                margin-bottom: 0px;
                // max-width: auto;
            }

            @media (min-width: variables.$lg) {
                padding-right: 60px;                
            }
            .recipe-actions-box {
                position: absolute;
                right: 0;
                top: 0;
                @media (min-width: variables.$md) {
                    right: 80px;
                }
            }

            .pfs-action-share-btn {
                display: inline-flex;
                @media (min-width: variables.$md) {
                    display: none;
                }
            }
        }

        .content-wrapper {
            flex: 1 1 50%;
            margin-top: 0;
            z-index: 1;

            @media (min-width: variables.$md) {
                margin-top: 60px;
            }

            .breadcrumbs {
                font-size: 12px;
                text-transform: uppercase;
                margin-top: 10px;

                @media(min-width: variables.$sm) {
                    font-size: 14px;
                }

                ul {
                    display: flex;
                    flex-direction: row;
                    li {
                        position: relative;
                        display: flex;
                        align-items: baseline;

                        &::after {
                            content: "";
                            padding: 0 8px;
                            display: inline-block;
                            width: auto;
                            height: 12px;
                            background: url(../img/_icn/arrow_flat_white.svg) no-repeat 0 0/12px 18px;
                            transform: rotate(-90deg);
                            margin: 0 5px;
                        }

                        &:last-child {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }

            .sph-title {
                position: relative;
                // margin: 15px 0;
                left: -15px;
                width: auto;                
                // font-size: 40px;
                // line-height: 42px;
                
                @media (min-width: variables.$md) {
                    left: -25%;
                    width: 125%;
                    font-size: 62px;
                    line-height: 66px;
                }

                @media(min-width: variables.$lg) {
                    font-size: 72px;
                    line-height: 76px;
                }
            }

            .sph-subtitle {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 24px;

                @media (min-width: variables.$md) {
                    font-size: 22px;
                    line-height: 36px;
                }
            }

            .sph-description {
            }

            .sph-text-wrapper {
                margin-bottom: 18px;
            }
        }
    }
    &--photo-library {
        background-color: variables.$pfs_yellow;
        .pfs-breadcrumbs {
            ul {
                li {
                    &::after {
                        color: #fff;
                    }
                    a {
                        color: #fff;
                    }
                    span {
                        color: #fff;
                    }
                }
            }
        }
        .pfs-container {
            .image-wrapper {
                z-index: 3;
                padding: 0 32px;
                @media (min-width: variables.$md) {
                    z-index: 1;
                    padding: 0;
                }

                img {
                    @media (min-width: variables.$lg) {
                        padding-right: 60px;
                    }
                }
            }
            .content-wrapper {
                position: relative;
                .sph-title {
                    left: 0;
                    @media (min-width: variables.$md) {
                        font-size: 40px;
                        line-height: 1;
                    }
                    @media (min-width: variables.$lg) {
                        left: -150px;

                        font-size: 60px;
                    }
                    @media (min-width: variables.$xl) {
                        font-size: 80px;
                    }
                }
                .sph-text {
                    padding-left: 28px;
                    @media (min-width: variables.$lg) {
                        padding-left: 0;
                    }
                }
            }
        }

        .wave-icon-deco {
            font-size: 180px;
            position: absolute;
            left: 50%;
            top: -20%;
            z-index: -1;
            @media (min-width: variables.$md) {
                font-size: 300px;
                // top: unset;
                left: -50px;
                top: -20%;
            }
            @media (min-width: variables.$lg) {
                font-size: 47vw;
                top: unset;
                left: -50px;
                bottom: 150px;
                z-index: -1;
            }
        }
        .ellipse-deco,
        .icon-x-mark-1 {
            position: absolute;
        }
        .ellipse-deco {
            border: 18px solid variables.$pfs_orange_light;
            border-radius: 60%;
            width: 60px;
            height: 60px;
            left: 40px;
            top: 50px;
            @media (min-width: variables.$md) {
                border-width: 40px;
                width: 160px;
                height: 160px;
                left: 2%;
                top: -2%;
            }
            @media (min-width: variables.$lg) {
                border-width: 70px;
                width: 295px;
                height: 295px;
                left: 2%;
                top: -7%;
            }
        }

        .icon-x-mark-1 {
            font-size: 10vh;
            left: 28px;
            bottom: 110px;
            @media (min-width: variables.$md) {
                font-size: 270px;
                left: 17%;
                bottom: -11%;
            }
        }
    }
    &.simple {
        min-height: auto;
        padding-bottom: 30px;
        padding-top: 20px;
        color: variables.$pfs_black;

        @media (min-width: variables.$md) {
            padding-top: 60px;
        }

        &--pb-0 {
            padding-bottom: 0;
        }

        &--brand,
        &--recipe {
            padding-top: 30px;
            padding-bottom: 0;

            @media (min-width: variables.$md) {
                padding-bottom: 60px;
                padding-top: 100px;
            }
        }

        .pfs-container {
            .content-wrapper {
                display: flex;
                flex-direction: column;

                &--recipe {
                    justify-content: center;
                    flex-direction: column;
                    margin-top: 0;

                    @media (min-width: variables.$md) {
                        flex-direction: column-reverse;
                    }

                    .time-and-cost {
                        .prep-time-wrapper {
                            display: inline-block;
                            padding-bottom: 3px;
                            margin-right: 36px;
                            &::before {
                                content: "\e907";
                                display: inline-block;
                                font-family: "icomoon";
                                margin-right: 2px;

                                margin-bottom: -3px;
                            }
                        }
                        .portion-cost-wrapper {
                            display: inline-block;
                            &::before {
                                content: "\e908";
                                display: inline-block;
                                font-family: "icomoon";
                                margin-bottom: -3px;
                            }
                        }

                        .info-box {
                            display: block;
                            font-size: 14px;
                            line-height: 14px;
                            color: variables.$pfs_grey_middle;
                        }
                    }

                    .sph-description {
                        margin-bottom: 0;

                        @media (min-width: variables.$md) {
                            margin-bottom: 36px;
                        }
                    }
                }

                .sph-title {
                    left: 0;
                    width: 100%;
                }

                .breadcrumbs {
                    ul {
                        flex-wrap: wrap;
                        li {
                            color: variables.$pfs_black;
                            white-space: nowrap;                            
                            &::after {
                                background: url(../img/_icn/arrow_flat.svg) no-repeat 0 0/12px 18px;
                            }
                            a {
                                color: variables.$pfs_grey_middle;
                            }
                        }
                    }
                }

                .sph-description {
                    padding-left: 28px;

                    @media (min-width: variables.$md) {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .anchor-down {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 50;
        display: flex;
        flex-direction: column;

        span {
            font-size: 30px;
            line-height: 12px;            
        }
        // .arrow-icon {
        //     display: block;
        //     width: 24px;
        //     height: 15px;
        //     &:last-child {
        //         position: relative;
        //         top: -3px;
        //     }
        // }
    }
}

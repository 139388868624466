@use "./variables";

.mb-0 {
    margin-bottom: 0;    
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-36 {
    margin-bottom: 36px;
}

.mb-72 {
    margin-bottom: 72px;
}

.pfs-bg {
    &--primary {
        background-color: variables.$pfs_primary;
    }
    &--primary {
        background-color: variables.$pfs_primary_light;
    }
    &--green-light {
        background-color: variables.$pfs_green_light;
    }
    &--cyan-light {
        background-color: variables.$pfs_cyan_light;
    }
    &--red {
        background-color: variables.$pfs_red;
    }
    &--red-light {
        background-color: variables.$pfs_red_light;
    }
    &--yellow {
        background-color: variables.$pfs_yellow;
    }
    &--yellow-light {
        background-color: variables.$pfs_yellow_light;
    }
}

.pfs-txt {
    &--primary {
        color: variables.$pfs_primary;
    }
    &--white {
        color: variables.$pfs_white;
    }
    &--green-light {
        color: variables.$pfs_green_light;
    }
    &--yellow {
        color: variables.$pfs_yellow;
    }
    &--red {
        color: variables.$pfs_red;
    }
    &--red-dark {
        color: variables.$pfs_red_dark;
    }
    &--orange {
        color: variables.$pfs_orange;
    }
    &--orange_light {
        color: variables.$pfs_orange_light;
    }
    &--uppercase {
        text-transform: uppercase;
    }
    &--align-right {
        text-align: right;
    }
}

.pfs-fill {
    &--red {
        path {
            fill: variables.$pfs_red;
        }
    }
    &--yellow {
        path {
            fill: variables.$pfs_yellow;
        }
    }
}
.pfs-page-loader{
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left:0;
    z-index: 250;
    align-items: center;
    justify-content: center;
    background-color: rgba(#fff , .8 );
    &.loading{
        display: flex;
    }
    svg{
        width: 100px;
        height: 100px;
        flex: 1;
    }
}
.pfs-vue-page-loader{
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left:0;

    z-index: 10;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    svg{
        width: 100px;
        height: 100px;
        flex: 1;
    }
}

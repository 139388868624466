@use "../utils/variables" as *;

.image-slider-sec {
    position: relative;
    .image-slider-arrows-box {
        position: absolute;

        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 50;
        pointer-events: none;
        .slick-arrow {
            &.slick-next,
            &.slick-prev {
                z-index: 5;
                pointer-events: auto;
            }
        }
        .slick-arrow.slick-next {
            right: 28px;
        }
        .slick-arrow.slick-prev {
            left: 28px;
        }
    }
    .slider-count-sec {
        padding-top: 15px;
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 20px;
        text-align: right;
        @media (min-width: $lg) {
            font-size: 24px;
            line-height: 26px;
        }
        .slider-count {
            display: inline-flex;
            width: 80px;
            align-items: center;
            justify-content: space-between;
        }
        .current-num,
        .total {
            display: block;
            // font-family:  $font-bold;
            // padding: 10px;
        }
        .total {
            color: $pfs_grey_middle;
            @media (min-width: $lg) {
                color: $pfs_black;
            }
        }
        .spacer {
            flex-grow: initial !important;
            &::before {
                content: "";

                transform: rotate(35deg);
                display: block;
                height: 34px;
                width: 3px;
                background: $pfs_grey_middle;
                @media (min-width: $lg) {
                    background: $pfs_black;
                    height: 37px;
                }
            }
        }
    }

    .image-slider {
        .slick-slide {
            position: relative;
            margin: 0 10px;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(#000, 0.4);
                opacity: 1;
            }
            &.slick-current {
                &::before {
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;
                }
            }
        }
        /* slide when active/center */
        .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
            &::before {
                opacity: 0;
                transition: opacity 0.5s ease-in-out;
            }
        }
    }
}

@use "../utils/variables" as *;
.pfs-profile {


    @media (min-width: 960px) {
        &::before {
            content: "";
            position: absolute;
            width: 50%;
            top: 0;
            bottom: 0;
            background-color: rgba($pfs_primary, 0.1);
        }
    }
    @media (min-width: $xl) {
        padding-bottom: 50px;
    }
    h1 {
        line-height: 1;
        font-size: 26px;
        @media (min-width: $sm) {
            font-size: 30px;
        }
        @media (min-width: $md) {
            font-size: 40px;
        }
        @media (min-width: $lg) {
            font-size: 50px;
        }
        @media (min-width: $xl) {
            font-size: 60px;
        }
        @media (min-width: $xxl) {
            font-size: 70px;
        }
        @media (min-width: $hd) {
            font-size: 80px;
        }
    }

    .inner-cel {
        padding-top: 50px;
        @media (min-width: $xl) {
            padding-top: 200px;
            padding-right: 60px;
        }
        &--profile {
        }
        &--restaurant {
            @media (min-width: $xl) {
                padding-left: 70px;
            }
        }
    }
    .pfs-v-form-sec {
        width: 100%;
        @media (min-width: $md) {
        }
        @media (min-width: $lg) {
            margin-left: 90px;
            width: 260px;
        }
        .pfs-form-group {
            margin-bottom: 20px;
        }
    }
    .profile-form {
    }
    .restaurant-form {
        margin-bottom: 30px;
    }
    .profile-menu {
        position: static;
        margin-left: -40px;
        margin-right: -40px;
        margin-bottom: -10px;
        @media (min-width: $xl) {
            min-width: 220px;
            margin: 0;
            position: absolute;
            right: 0;
            top: 10px;
        }
        a {
            text-transform: uppercase;
            font-size: 18px;
            line-height: 28px;
            // font-family:  $font-regular;
            font-weight: 400;
            padding-right: 36px;
        }
        .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
            color: $pfs_primary !important;
            &:hover,
            &:focus {
                background-color: rgba($pfs_primary, 0.1);
            }
        }
    }

    .v-list-item__title {
        font-size: 14px;
        // font-family:  $font-regular;
        font-weight: 400;
    }
    .v-input {
        // font-size: 14px;
    }
}

.logo-upload-error-box,
.logo-upload-error-box-custom{
	width: 90%;
	left: 5%;
	top: 5%;
	position: absolute;
	.error-message {
		display: none;

		line-height: 1;

		font-size: 12px;
		color: #e30613;
	}

	.error-message.has-error {
		display: block;
	}
}
.file-uploader {
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
}
.input-block {
	float: none;
	width: 93px;
	height: 93px;
	padding: 0;
	margin-right: 5px;
	margin-bottom: 10px;
	display: inline-block;
	position: relative;
	vertical-align: top;
	background-color: #c4c4c4;
}

.custom-logo-upload-info {
	font-size: 12px;
	opacity: 0.8;
}

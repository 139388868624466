@use "../utils/variables" as *;

.pfs-menu-preview {
  background-image: url("../img/stripe-menu-preview-1.png");
  background-repeat: repeat;

  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: $md) {
    height: 100%;
  }

  .menu-preview-box {
    position: relative;
    width: 100%;
    // overflow: hidden;

    &.menuboard_16-9,
    &.deliveroo_hero_image_16-9 {
      .drink-image {
        // transform: translate(-7%, 4%);
        transform: translate(0, 4%);
      }
    }

    &.uber_eats_6-4 {
      .surface-image {
        transform: scale(1.2);
        bottom: 4%;
      }

      .dish-image {
        // transform: scale(1.2);
        // left: 5%;
        transform: scale(1.2) translate(-5%, 2%);
        transform-origin: bottom;
      }

      .snack-image {
        // transform: scale(1.2);
        // left: 4%;
        // bottom: 0;
        transform: scale(1.2) translate(4%, 5%);
        transform-origin: bottom;
      }

      .drink-image {
        // transform: scale(1.2);
        // left: 4%;
        transform: scale(1.2) translate(0%, 7%);
        transform-origin: bottom;
      }
    }

    &.deliveroo_product_image_4-3 {
      .surface-image {
        transform: scale(1.6);
        bottom: 11%;
      }

      .dish-image {
        // transform: scale(1.4);
        // left: 13%;
        transform: scale(1.3) translate(-4%, 0%);
        transform-origin: bottom;
      }

      .snack-image {
        // transform: scale(1.4);
        // left: 10%;
        // bottom: 0;
        transform: scale(1.3) translate(2%, 5%);
        transform-origin: bottom;
      }

      .drink-image {
        // transform: scale(1.4);
        // left: 10%;
        transform: scale(1.3) translate(0%, 6%);
        transform-origin: bottom;
      }
    }

    &.just_eat_1-1 {
      .logo-text-box {
        transform: scale(1.3);
        transform-origin: top right;
      }

      .surface-image {
        transform: scale(1.8);
        bottom: 15%;
      }

      .dish-image {
        transform: scale(1.5) translate(0%, 0%);
        transform-origin: bottom;
      }

      .snack-image {
        transform: scale(1.5) translate(0%, 4%);
        transform-origin: bottom;
      }

      .drink-image {
        transform: scale(1.5) translate(0%, 7%);
        transform-origin: bottom;
      }
    }

    &.print_a4 {
      .logo-text-box {
        transform: scale(1.5);
        transform-origin: top right;
      }

      .surface-image {
        transform: scale(2);
        bottom: 19%;
      }

      .dish-image {
        transform: scale(1.5) translate(-3%, 0%);
        transform-origin: bottom;
      }

      .snack-image {
        transform: scale(1.5) translate(0%, -5%);
        transform-origin: bottom;
      }

      .drink-image {
        transform: scale(1.5) translate(-4%, 3%);
        transform-origin: bottom;
      }
    }

    &.instagram_size_4-5 {
      .logo-text-box {
        transform: scale(1.5);
        transform-origin: top right;
      }

      .surface-image {
        transform: scale(2);
        bottom: 21%;
      }

      .dish-image {
        transform: scale(1.5) translate(-2%, 0%);
        transform-origin: bottom;
      }

      .snack-image {
        transform: scale(1.5) translate(0%, -5%);
        transform-origin: bottom;
      }

      .drink-image {
        transform: scale(1.5) translate(-3%, 3%);
        transform-origin: bottom;
      }
    }

    &.center-snack {
      .snack-image {
        transform: translate(20%, 6%);
      }

      &.uber_eats_6-4 {
        .snack-image {
          transform: translate(23%, 10%) scale(1.2);
        }
      }

      &.deliveroo_product_image_4-3 {
        .snack-image {
          transform: translate(28%, 10%) scale(1.3);
        }
      }

      &.just_eat_1-1 {
        .snack-image {
          transform: translate(28%, 10%) scale(1.5);
        }
      }

      &.print_a4 {
        .snack-image {
          transform: translate(30%, 10%) scale(1.5);
        }

      }

      &.instagram_size_4-5 {
        .snack-image {
          transform: translate(32%, 10%) scale(1.5);
        }
      }
    }

    &.center-dish {
      .dish-image {
        transform: translate(-9%, 1%);

      }

      .snack-image {
        transform: translate(3%, 1%);
      }

      &.uber_eats_6-4 {
        .dish-image {
          transform: translate(-8%, 5%) scale(1.2);
        }
        .snack-image {
          transform: translate(4%,1%) scale(1.2);
        }
      }

      &.deliveroo_product_image_4-3 {
        .dish-image {
          transform: translate(-8%, 1%) scale(1.2);
        }

        .snack-image {
          transform: translate(3%, 1%) scale(1.2);
        }
      }

      &.just_eat_1-1 {
        .dish-image {
          transform: translate(-10%, 1%) scale(1.4);
        }

        .snack-image {
          transform: scale(1.5) translate(2%, 0%);
        }
      }

      &.print_a4 {
        .dish-image {
          transform: translate(-11%, 1%) scale(1.5);
        }

        .snack-image {
          transform: translate(3%, 1%) scale(1.5);
        }
      }

      &.instagram_size_4-5 {
        .dish-image {
          transform: translate(-5%, 1%) scale(1.5);
        }
        .snack-image {
          transform: translate(1%,1%) scale(1.5);
        }
      }
    }

    &.to-left-drink {


      &.menuboard_16-9,
      &.deliveroo_hero_image_16-9 {
        .drink-image {
          transform: translate(-6%, 4%);
        }
      }

      &.uber_eats_6-4 {
        .drink-image {
          transform: scale(1.2) translate(-4%, 7%);
        }
      }

      &.deliveroo_product_image_4-3 {
        .drink-image {
          transform: scale(1.3) translate(-6%, 6%);
        }
      }

      &.just_eat_1-1 {
        .drink-image {
          transform: scale(1.5) translate(-8%, 7%);
        }
      }

      &.print_a4 {
        .drink-image {
          transform: scale(1.5) translate(-8%, 3%);
        }
      }

      &.instagram_size_4-5 {
        .drink-image {
          transform: scale(1.5) translate(-9%, 3%);
        }
      }

      &.center-snack {
        &.menuboard_16-9,
        &.deliveroo_hero_image_16-9 {
          .drink-image {
            transform: translate(0%, 4%);
          }
        }

        &.uber_eats_6-4 {
          .drink-image {
            transform: scale(1.2) translate(0%, 7%);
          }
        }

        &.deliveroo_product_image_4-3 {
          .drink-image {
            transform: scale(1.3) translate(1%, 6%);
          }
        }

        &.just_eat_1-1 {
          .drink-image {
            transform: scale(1.5) translate(0%, 7%);
          }
        }

        &.print_a4 {
          .drink-image {
            transform: scale(1.5) translate(0%, 3%);
          }
        }

        &.instagram_size_4-5 {
          .drink-image {
            transform: scale(1.5) translate(2%, 3%);
          }
        }
      }
    }
  }

  .menu-preview-back-image-mask {
    background-color: #000;

    img {
      opacity: 0.5;
    }
  }

  .menu-preview {
    position: absolute;
    bottom: 0;
    width: 100%;
    overflow: hidden;
  }

  .background-image,
  .surface-image,
  .dish-image,
  .snack-image,
  .drink-image {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .surface-image {
    z-index: 5;
  }

  .snack-image {
    z-index: 10;
  }

  .dish-image {
    z-index: 15;
  }

  .drink-image {
    z-index: 20;
  }

  .logo-text-box {
    position: absolute;
    top: 0px;
    right: 30px;
    width: 180px;
    z-index: 25;
    transform-origin: top right;
    @media (min-width: $xl) {
      // transform: scale(0.3);
      //    x =  600/180
    }

    &.logo-hidden {
      margin-top: 5%;
    }

    .logo-image {
      width: 70%;
    }

    .menu-text-box {
      background-color: $pfs_primary;
      padding: 1px 10px;
      color: #fff;
      z-index: 10;
      margin-bottom: 12px;

      .menu-headline {
        font-weight: normal;
        font-size: 12px;
        line-height: 1;
        margin: 10px 0;
      }

      .menu-text {
        font-size: 11px;
        line-height: 1.4;
        margin: 10px 0;
        white-space: pre-wrap;
      }
    }

    .menu-price-box {
      display: flex;
      align-items: center;
      z-index: 50;
      transform-origin: top right;

      .menu-price {
        background-color: #e62d36;
        color: #fff;
        font-weight: normal;
        font-size: 22px;
        line-height: 1;
        padding: 5px 5px;
        font-weight: 700;
        max-width: 100px;
      }

      .menu-description {
        color: #fff;
        font-size: 11px;
        line-height: 1;
        text-transform: uppercase;
        padding-left: 10px;
        max-width: 75px;

        .dish-name {
        }

        .drink-name {
          font-weight: 700;
        }

        .snack-name {
          font-weight: 700;
        }
      }
    }
  }
}

.custom-logo-input-wrap {
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

#custom_logo_preview {
  height: 0;
  line-height: 0;
}

.hidden-file-input {
  height: 0;
  width: 0;
  opacity: 0;
}

@use "../utils/variables";

.pfs-component-featured-article {
    // padding: 80px 28px;
    // background-size: cover;
    // background-position: center center;
    // background-repeat: no-repeat; 
    text-align: center;
    position: relative;
    height: 520px;

    @media(min-width: variables.$md) {
        // padding: 120px 0 110px 0;
    }

    .pfs-fa-cover-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .pfs-fa-container {
        max-width: 625px;
        margin: 0 auto;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .pfs-fa-category {
            text-transform: uppercase;
        }
    
        .pfs-fa-description {
            padding-bottom: 25px;
        }
    }
}
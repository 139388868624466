@use "../utils/variables";

.pfs-icon-tiles-component {
    margin-bottom: 36px;

    .tile-container {
        display: flex;
        justify-content: center;
        flex-direction: row;

        .tile-item {
            padding: 30px 10px;
            width: 256px;
            height: 256px;   
            max-width: 256px;
            max-height: 256px;         
            margin: 0 10px;
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            // justify-content: space-around;
            text-align: center;
            overflow: hidden;

            svg {
                display: block;
                margin: 0 auto;
            }

            p {
                font-size: 14px;
                line-height: 24px;;
            }

            h3 {
                margin: 30px 0 20px;
            }
            .tile-item-icon{
                // color: variables.$pfs_red_dark;
                font-size: 50px;
            }
        }
    }
}
@use "../utils/variables" as *;

.pfs-accordion {
    .collapse {
        .collapse-header {
           
            border-bottom: 1px solid $pfs_red_dark;
           
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 20px 0;
            @media (min-width: $md){
                padding: 20px 25px;
            }
            .title {
                order: 2;
                
                font-weight: 700;
                display: inline-block;
                margin-left: 25px;
                font-size: 18px;
            }
            svg {
                width: 25px;
                height: 16px;
                order: 1;
                transition: transform 0.3s ease-out;
            }
            &.active {
                svg {
                    transform: scaleY(-1);
                }
            }
        }
        .collapse-item {
            display: none;
            padding: 15px 0 ;
        }
    }
    table{
        font-size: 14px;
        td, th {
           border-color: $pfs_grey_middle;
        }
    }
}

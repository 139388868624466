@use "../utils/variables";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-size: 18px;
  line-height: 28px;
  font-family: variables.$font-family;
  font-weight: 500;
  // overflow: hidden;

  &.menu-open {
    position: fixed;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a,
a:hover,
a:active,
a:focus,
a:visited {
  text-decoration: none;
  color: inherit;
}

img,
svg,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.pfs-container-main {
  width: 100%;
  position: relative;
  // padding-bottom: 60px;
  overflow: hidden;
  min-height: 100%;
  padding-bottom: 110px;
  @media (min-width: variables.$sm) {
    padding-bottom: 85px;
  }
  .coming-soon-page & {
    padding-bottom: 80px;
  }
}

//          full    &   xl      &   sm
//----------------------------------------------
// Header   100px   &   100px   &   80px
// Footer   90px    &   90px    &   115px
//----------------------------------------------
.pfs-container-fluid {
  width: 100%;

  &--full-viewport {
    // Window Height minus Height of the Header + Main Container bottom padding
    height: calc(100vh - 195px);
    overflow-x: hidden;
    overflow-y: auto;

    .coming-soon-page & {
      height: calc(100vh - 164px);
    }

    @media (min-width: variables.$md) {
      overflow-y: hidden;
      height: calc(100vh - 165px);
    }

    @media (min-width: variables.$xl) {
      height: calc(100vh - 185px);

      .coming-soon-page & {
        height: calc(100vh - 180px);
      }
    }
  }
}

.pfs-container-medium {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.pfs-container {
  width: 100%;
  max-width: 1316px;
  margin: 0 auto;
  padding: 0 28px;
}

.v-application .pfs-btn,
.pfs-btn {
  display: inline-block;
  text-transform: uppercase;
  padding: 11px 25px;
  min-height: 50px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  outline: none;
  width: 253px;
  text-align: center;
  color: variables.$pfs_white;

  &--primary {
    background-color: variables.$pfs_primary;
    color: variables.$pfs_white;

    &:hover {
      background-color: variables.$pfs_primary_light;
      color: variables.$pfs_white;
    }
    &:focus,
    &:active,
    &:visited {
      color: variables.$pfs_white;
    }
  }
  &--inverse {
    background-color: variables.$pfs_white;
    border: 2px solid variables.$pfs_primary;
    color: variables.$pfs_primary;
  }
}

.pfs-btn-group {
  width: 100%;
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > .pfs-btn {
    margin: 10px;
  }
}

.hide-lg {
  display: block;

  @media (min-width: variables.$lg) {
    display: none;
  }
}

.show {
  display: initial;
}

.hide {
  display: none;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-36 {
  margin-bottom: 36px;
}

.cc-section {
  display: flex;
  justify-content: center;
}

.center-image {
  display: block;
  margin: 0 auto;
}

.center-text {
  text-align: center;
}

.pfs-row {
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
  flex-wrap: wrap;
  // width: 100%;

  &.pfs-no-gutters {
    margin: 0;
    .pfs-col {
      margin: 0;
      &.pfs-col-50 {
        @media (min-width: variables.$md) {
          max-width: 50%;
        }
      }
    }
  }

  @media (min-width: variables.$md) {
    flex-direction: row;

    &--reverse {
      flex-direction: row-reverse;
    }
  }
  .pfs-col {
    flex: 1 1 100%;
    max-width: 100%;
    margin: 0 10px;
  }

  .pfs-col-50 {
    flex: 1 1 100%;
    max-width: 100%;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media (min-width: variables.$md) {
      // flex-basis: calc(50% - 20px);
      max-width: calc(50% - 20px);
    }

    &--left-space {
      flex-direction: row;

      &::before {
        content: "";
        display: none;
        min-width: 28px;
        flex: 1 1 auto;

        @media (min-width: variables.$md) {
          display: block;
        }
      }

      .col-content {
        max-width: 100%;
        padding: 0 28px;

        @media (min-width: variables.$md) {
          max-width: 620px;
          padding: 0;
        }

        &--center-content {
          align-self: center;
        }
      }
    }
  }
  .pfs-col-md-40 {
    @media (min-width: variables.$md) {
      // flex-basis: calc(50% - 20px);
      max-width: calc(40% - 20px);
    }
  }
  .pfs-col-md-60 {
    @media (min-width: variables.$md) {
      // flex-basis: calc(50% - 20px);
      max-width: calc(60% - 20px);
    }
  }
  .pfs-col-lg-40 {
    @media (min-width: variables.$lg) {
      // flex-basis: calc(50% - 20px);
      max-width: calc(40% - 20px);
    }
  }
  .pfs-col-lg-60 {
    @media (min-width: variables.$lg) {
      // flex-basis: calc(50% - 20px);
      max-width: calc(60% - 20px);
    }
  }
}
.h-100 {
  height: 100%;
}

.text-2-columns {
  margin-bottom: 50px;
  @media (min-width: variables.$md) {
    column-count: 2;
    column-gap: 30px;
  }
}

// .filter-wrapper {
//     .v-form {
//         .v-select,
//         .v-text-field {
//             // font-family:  $font-light;
//         }
//     }
// }

.no-uppercase {
  text-transform: none;
}

:focus {
  outline: none;
}

[v-cloak] > * {
  display: none;
}
.small-font {
  label {
    font-size: 14px;
  }
}

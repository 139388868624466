@use "../utils/variables" as *;

.pfs-menu-generator {
  .pfs-row {
    margin: 0;

    .pfs-col-50 {
      display: block;
      margin: 0;
      @media (min-width: 768px) {
        max-width: 50%;
      }
    }
  }
  
  .second-logo-selection {
    position: relative;
    margin-bottom: 2rem;
    
    &__inner {
      display: inline-block;
      position: relative;
    }

    .custom-logo-upload-box {
      position: relative;
      color: $pfs_primary;
      border: 3px solid $pfs_grey_light;
      width: 108px;
      height: 108px;
      margin-bottom: 1rem;

      .custom-logo-upload-box-inner {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        line-height: 1;

        .custom-logo-upload-icon {
          font-size: 34px;
          margin-bottom: 10px;
        }

        .custom-logo-upload-text {
          font-size: 14px;
          line-height: 1.1;
        }
      }

      &:hover,
      &:focus {
        cursor: pointer;
      }
    }
  }

  .v-tabs {
    margin-bottom: 30px;
    padding-top: 40px;
    @media (min-width: $lg) {
      padding-top: 40px;
    }

    .v-slide-group__prev.v-slide-group__prev--disabled {
      display: none !important;
    }

    .v-tab {
      text-transform: none;
    }
  }

  .menu-gen-col-inner-editor {
    padding: 0 15px;
    @media (min-width: $lg) {
      padding-left: 40px;
      padding-right: 0;
    }
  }

  .bis-item {
    border: 6px solid transparent;

    &.active {
      border-color: $pfs_primary;
    }
  }

  .menu-generator-info {
    padding-top: 40px;
    margin-bottom: 40px;
    display: none;
    @media (min-width: $lg) {
      display: block;
    }

    .pfs-breadcrumbs {
      ul {
        padding-left: 0;
      }
    }
  }

  .v-tabs-bar__content {
    .v-tabs-slider-wrapper {
      display: none;
    }

    .v-tab {
      background-color: $pfs_grey_lighter;
      font-size: 14px;
      padding: 0 20px;

      &:after {
        content: "";
        position: absolute;
        height: 60%;
        width: 1px;
        background-color: $pfs_grey_middle;
        right: -1px;
        top: 20%;
        z-index: 1;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &.v-tab--active {
        background-color: $pfs_primary;
        color: #fff;
        border-color: transparent;

        &:after {
          display: none;
        }
      }
    }
  }

  .tab-slider {
    @media (min-width: $xl) {
      padding: 0 68px;
    }
    margin-bottom: 30px;

    .slick-arrow.slick-next {
      right: -68px;
    }

    .slick-arrow.slick-prev {
      left: -68px;
    }

    .menu-generator-slider {
      @media (min-width: $lg) {
        width: 360px;
      }

      .bis-item {
        position: relative;
        object-fit: contain;
        object-position: 50% 50%;

        img {
          width: 100%;
          object-fit: contain;
        }

        .custom-logo-upload-box {
          padding-top: 100%;
          position: relative;
          color: $pfs_primary;
          border: 3px solid $pfs_grey_light;

          .custom-logo-upload-box-inner {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            text-align: center;
            line-height: 1;

            .custom-logo-upload-icon {
              font-size: 34px;
              margin-bottom: 10px;
            }

            .custom-logo-upload-text {
              font-size: 14px;
              line-height: 1.1;
            }
          }

          &:hover,
          &:focus {
            cursor: pointer;
          }
        }
      }
    }
  }

  .menu-gen-action {
    border-top: 1px solid $pfs_grey_middle;
    padding-top: 15px;
    padding-bottom: 20px;

    .menu-gen-sub-title {
      margin-bottom: 10px;
    }

    .v-input--selection-controls {
      margin: 0;
      padding: 0;
    }

    .formats-box {
      display: flex;
      margin-bottom: 20px;

      label {
        margin-right: 30px;
      }
    }

    .btn-box {
      .v-btn {
        width: 280px;
      }
    }
  }

  .tab-item-format {
    .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
      margin-bottom: 20px;
    }
  }

  .extras-tab-text-sec {
    width: 256px;
  }

  .input-menu-text-box {
    margin-bottom: 30px;
  }

  .textarea-auto-rows {
    // background-color: red;
    textarea {
      // height: auto !important;
    }
  }
}


//.helper-square {
//  //padding-bottom: 100%;
//  //width: 300px;
//  position: absolute;
//  border: 2px solid red;
//  z-index: 5000;
//  z-index: 5000;
//  left: 50%;
//  top: 50%;
//  transform: translate(-50%, -50%);
//}
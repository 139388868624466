@use "../utils/variables";

.pfs-header {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 99;
    background: variables.$pfs_white;

    .header-component {
        display: flex;
        flex-direction: row;
        height: 80px;
        max-width: variables.$content-width;
        width: 100%;
        padding: 0 28px;
        margin: 0 auto;
        align-items: stretch;
        justify-content: space-between;
        background: variables.$pfs_white;
        color: variables.$pfs_primary;

        @media (min-width: variables.$xl) {
            padding: 0;
            height: 100px;
        }
        // @media (min-width: variables.$xl) {
        //     padding: 0;
        // }

        &.search-active {
            .nav-brand {
                display: none;
            }
            .nav-links {
                display: none;
            }
            .right-nav-items {
                width: 100%;

                .nav-search {
                    overflow: visible;
                    width: 100%;
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -5px;
                        width: 100%;
                        height: 1px;
                        background-color: variables.$pfs_primary;
                    }

                    input {
                        outline: none;
                        display: block;
                    }

                    .close-search {
                        display: block;
                        z-index: 1;
                    }
                }
            }
        }

        .nav-brand {
            width: 125px;
            height: auto;
            align-self: center;

            @media (min-width: variables.$xl) {
                width: 188px;
            }
        }

        .nav-links {
            flex-grow: 2;
            padding: 0 28px;
            display: none;

            @media (min-width: variables.$xl) {
                display: block;
            }

            .nav-links-wrapper {
                display: table;
                width: 100%;
                height: 100%;
                text-align: center;
                ul.main-menu {
                    font-size: 13px;

                    @media(min-width: 1280px) {
                        font-size: 14px;
                    }

                    & > li {
                        position: relative;
                        display: inline-block;
                        // display: table-cell;
                        & > a {
                            // background-color: green;
                            display: block;
                            padding: 36px 12px;
                        }

                        & > ul.submenu {
                            position: absolute;
                            left: 0;
                            display: block;
                            li{
                               &> a{

                                }
                            }
                        }
                    }
                }
                ul {
                    display: table-row;

                    li {
                        display: table-cell;
                        vertical-align: middle;
                        position: relative;

                        a {
                            &:hover {
                                color: variables.$pfs_cyan_light;
                            }

                            .pfs-ico {
                                // padding-left: 20px;
                                display: inline-block;
                                font-size: 16px;
                                padding-right: 8px;
                                // // font-family:  'icomoon';

                                &--end {
                                    float: right;
                                    font-size: 18px;
                                    padding-right: 0;
                                    padding-top: 6px;
                                }

                                // &--mail {
                                //     // background: url(../img/_icn/mail.svg) no-repeat 0 0/15px 28px;
                                //     &::before {
                                //         content: '\e90a';
                                //     }
                                // }
                                // &--leaf {
                                //     &::before {
                                //         content: '\e90a';
                                //     }
                                //     // background: url(../img/_icn/leaf.svg) no-repeat 0 0/15px 28px;
                                // }
                                // &--paper {
                                //     &::before {
                                //         content: '\e90a';
                                //     }
                                //     // background: url(../img/_icn/paper.svg) no-repeat 0 0/15px 28px;
                                // }
                                // &--book {
                                //     &::before {
                                //         content: '\e90a';
                                //     }
                                //     // background: url(../img/_icn/book.svg) no-repeat 0 0/15px 28px;
                                // }
                                // &--crown {
                                //     &::before {
                                //         content: '\e90a';
                                //     }
                                //     // background: url(../img/_icn/crown.svg) no-repeat 0 0/15px 28px;
                                // }
                            }
                        }

                        &:hover {
                            ul.submenu {
                                opacity: 1;
                                visibility: visible;
                            }
                        }

                        ul {
                            &.submenu {
                                display: flex;
                                opacity: 0;
                                visibility: hidden;
                                transition: opacity 0.2s linear;
                                width: 240px;
                                padding: 18px 8px 14px 15px;
                                background: variables.$pfs_white;
                                position: absolute;
                                right: 0;
                                top: 100px;
                                text-transform: uppercase;
                                flex-direction: column;
                                justify-content: space-between;
                                text-align: left;
                                box-shadow: 0px 4px 15px rgba(0, 76, 151, 0.4);
                                z-index: 1;

                                li {
                                    margin: 5px 0;

                                    a {
                                        &.pfs-ico {
                                            padding-left: 0px;
                                            display: block;
                                            background-size: 18px;
                                            background-repeat: no-repeat;
                                            background-position: center right;

                                            &--star {
                                                background-image: url(../img/_icn/star.svg);
                                            }
                                            &--star-active {
                                                background-image: url(../img/_icn/star_active.svg);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                ul.submenu {
                    display: block;
                    li {
                        display: block;
                    }
                }
            }
        }

        .right-nav-items {
            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 5;
            width: auto;
            translate: width 0.3s linear;

            span {
                &.nav-search {
                    margin-right: 30px;
                    cursor: pointer;
                    // padding-bottom: 4px;
                    position: relative;
                    width: 30px;
                    overflow: hidden;
                    // font-family:  variables.$font-light, Arial, sans-serif;

                    input {
                        border: none;
                        position: absolute;
                        left: 30px;
                        bottom: 0;
                        width: 100%;
                        display: none;
                    }

                    .close-search {
                        position: absolute;
                        right: 5px;
                        top: 5px;
                        display: none;
                        width: 15px;
                        height: 15px;
                        background: url(../img/_icn/close.svg) no-repeat center;
                    }
                }
                &.nav-profile {
                    display: none;
                    cursor: pointer;

                    @media (min-width: variables.$xl) {
                        display: block;
                    }
                }
                &.nav-burger {
                    display: block;
                    cursor: pointer;

                    @media (min-width: variables.$xl) {
                        display: none;
                    }

                    &.open {
                        span {
                            background-color: variables.$pfs_white;

                            &:nth-child(2) {
                                &::before {
                                    transform: rotate(45deg);
                                }
                                &::after {
                                    transform: rotate(-45deg);
                                }
                            }
                        }
                    }

                    span {
                        display: block;
                        width: 24px;
                        height: 3px;
                        background-color: variables.$pfs_primary;
                        transition: background-color 0.1s linear;

                        &:nth-child(2) {
                            margin: 6px 0;
                            position: relative;

                            &::before {
                                content: "";
                                position: absolute;
                                width: 24px;
                                height: 3px;
                                display: block;
                                background-color: variables.$pfs_primary;
                                top: 0;
                                left: 0;
                                transform-origin: center center;
                                transform: rotate(0deg);
                                transition: transform 0.1s linear;
                            }
                            &::after {
                                content: "";
                                position: absolute;
                                width: 24px;
                                height: 3px;
                                display: block;
                                background-color: variables.$pfs_primary;
                                top: 0;
                                left: 0;
                                transform-origin: center center;
                                transform: rotate(0deg);
                                transition: transform 0.1s linear;
                            }
                        }
                    }
                }
            }
        }

        .mobile-menu {
            height: 100%;
            background: variables.$pfs_white;
            z-index: 4;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            transform: translateX(100%);
            width: 100%;
            transition: 0.4s ease-in-out;
            overflow-y: auto;

            &.open {
                transform: translateX(0);
                -webkit-transform: translateX(0);
            }

            .mobile-menu-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                height: 100%;

                .mobile-nav {
                    margin-top: 60px;
                    width: 100%;

                    li {
                        padding: 0 28px;
                        border-left: 4px solid transparent;
                        border-bottom: 1px solid variables.$pfs_grey_light;
                        // position: relative;

                        // &::after {
                        //     content: "";
                        //     display: block;
                        //     position: absolute;
                        //     bottom: 0;
                        //     height: 1px;
                        //     width: 90%;
                        //     margin-left: 5%;
                        // }

                        &.active {
                            color: variables.$pfs_cyan_light;
                            border-left: 4px solid variables.$pfs_cyan_light;

                            .has-submenu {
                                &::after {
                                    transform: rotate(0);
                                }
                            }

                            .submenu {
                                height: auto;
                                color: variables.$pfs_primary;

                                li {
                                    border: none;
                                }

                                .active {
                                    color: variables.$pfs_cyan_light;
                                    border-left: none;
                                }
                            }
                        }

                        // &.active .has-submenu {
                        //     &::after {
                        //         transform: rotate(0);
                        //     }
                        // }

                        // &.active .submenu {
                        //     height: auto;
                        // }

                        a {
                            // width: 100%;
                            padding: 8px 0;
                            // border-bottom: 1px solid variables.$pfs_grey_light;
                            display: inline-block;

                            &.has-submenu {
                                &::after {
                                    pointer-events: none;
                                    position: absolute;                                    
                                    content: "\e900";
                                    font-family: "icomoon";
                                    // width: 12px;
                                    // height: 8px;
                                    // background: url(../img/_icn/arrow_flat.svg) no-repeat 0 0/12px;
                                    display: inline-block;
                                    transform: rotate(180deg);
                                    transform-origin: center center;
                                    margin: 0 0 2px 10px;
                                }
                            }

                            .pfs-ico {
                                padding-right: 14px;
                                font-size: 16px;
                                // padding-left: 36px;

                                &--end {
                                    padding-right: 0;
                                    float: right;
                                    padding-top: 3px;
                                }
                            }
                        }

                        .submenu {
                            margin-top: 10px;
                            height: 0;
                            overflow: hidden;
                            transition: height 0.2s linear;

                            li {
                                text-transform: uppercase;

                                a {
                                    border: none;

                                    &.pfs-ico {
                                        padding-left: 0px;
                                        display: block;
                                        background-size: 18px;
                                        background-repeat: no-repeat;
                                        background-position: center right;

                                        &--star {
                                            background-image: url(../img/_icn/star.svg);
                                        }
                                        &--star-active {
                                            background-image: url(../img/_icn/star_active.svg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .profile-wrapper {
                    width: 100%;
                    background-color: rgba(0, 76, 151, 0.15);
                    padding: 25px 35px;
                    color: variables.$pfs_black;

                    a {
                        display: block;
                        margin: 0 auto 10px;
                    }

                    &--logged-in {
                        a {
                            color: variables.$pfs_primary;
                            text-transform: uppercase;

                            &:hover {
                                color: variables.$pfs_cyan_light;
                            }

                            > span {
                                font-size: 20px;
                                margin-right: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}

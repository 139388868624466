@use "../utils/variables";

.pfs-news-article {
    margin-bottom: 36px;
    &.pfs-row:nth-child(even){
        flex-direction: row-reverse;
    }
    
    .content-wrapper {

        .date {
            text-transform: uppercase;
        }

        h3 {
            text-transform: uppercase;
        }
        .na-description {
            margin-bottom: 24px;
        }
    }
}
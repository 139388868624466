.pfs-two-column-grid {    
    margin-bottom: 36px;

    .category {
        text-transform: uppercase;
    }
    span {
        &.category {
            font-size: 14px;
            line-height: 24px;
        }
    }
    h2,
    h3 {
        margin: 5px 0 8px; 
    }

    div[class*="pfs-col"] {
        margin-bottom: 36px;

        &.mb-0 {
            margin-bottom: 0;
        }
    }
}
// typography rules and typography helper classes
@use "../utils/variables";

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    margin: 8px 0 16px;
}

h1 {
    font-size: 40px;
    line-height: 42px;
    // // font-family:   variables.$font-bold;
    font-weight: 900;
    text-transform: uppercase;

    @media (min-width: variables.$md) {
        font-size: 80px;
        line-height: 84px;
    }
}

h2 {
    font-size: 30px;
    line-height: 34px;
    // // font-family:   variables.$font-bold;
    font-weight: 900;

    @media (min-width: variables.$md) {
        font-size: 46px;
        line-height: 50px;
    }
}

h3 {
    font-size: 24px;
    line-height: 26px;
    // // font-family:   variables.$font-bold;
    font-weight: 700;
}

h4 {
    font-size: 24px;
    line-height: normal;
    // // font-family:   variables.$font-regular;
    font-weight: 500;
}

h5 {
    font-size: 18px;
    line-height: normal;
    // // font-family:   variables.$font-bold;
    font-weight: 700;
}

p {
    font-size: 18px;
    line-height: 28px;
    // // font-family:   variables.$font-regular;
    font-weight: 500;
}

.copy-large {
    font-size: 18px;
    line-height: 26px;
    // // font-family:   variables.$font-regular;
    font-weight: 500;

    @media (min-width: variables.$md) {
        font-size: 22px;
        line-height: 36px;
    }
}

.copy-small {
    font-size: 14px;
    line-height: 24px;
    // // font-family:   variables.$font-regular; 
    font-weight: 500;
}

.text-bold {
    // // font-family:   variables.$font-bold;
    font-weight: 700;
}

.list-check-sec {
    ul {
        padding-top: 30px;
        li {
            margin-bottom: 15px;
            &::before {
                content: "\e910";
                margin-right: 5px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                // width: 24px;
                // height: 24px;
                font-family: "icomoon" !important;
                font-size: 16px;
                margin-right: 10px;
                // display: inline-block;
                // background-image: url("../img/_icn/checkmark.svg");
            }
        }
    }
}

table {
    width: 100%;
    margin-bottom: 30px;
    background-color: transparent;
    border-collapse: collapse;
    tr {
        th{
            // // font-family:   variables.$font-bold;
            font-weight: 700;
        }
        td, th {
            padding: 10px;
            vertical-align: top;
            border-top: 1px solid variables.$pfs_grey_light;
        }
    }
}
.pfs-responsive-table{
    overflow-x: scroll;
}
@use "../utils/variables" as *;

.pfs-product-detail {
    background: #fff;
    @media (min-width: $md) {
        margin-bottom: 36px;
    }
    @media (min-width: $lg) {
        background: linear-gradient(90deg, rgba(#f6e7e8, 0) 50%, rgba(#f6e7e8, 1) 50%);
    }
}
.product-detail-grid {
    @media (min-width: $lg) {
        // display: grid;
        // grid-template-columns: 45% 55%;

        display: flex;
        .product-detail-slider-sec {
            flex: 1 1 45%;
        }
        .product-detail-content{
            flex: 1 1 55%
        }
    }
}
.product-detail-content {
    background-color: #f6e7e8;
    position: relative;
    margin: 0 -28px;
    padding: 20px 28px 30px 28px;
    @media (min-width: $lg) {
        padding-left: 70px;
        padding-top: 235px;
        padding-bottom: 185px;
        padding-right: 0;
        margin: 0;
    }
    h4,
    h2 {
        text-transform: uppercase;
    }
}

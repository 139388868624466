@use "../utils/variables" as *;

.registration-form-page {
    .theme--light.v-application {
        // background-color: transparent;
        .form-body {
            .v-btn {
                display: flex;
                width: 100%;
                @media (min-width: $lg) {
                    display: inline-flex;
                    width: auto;
                }
            }
        }
    }
    .invert-cols {
        .pfc-register-text-col {
            order: 2;
            position: relative;
            padding-left: 50px;
        }
        .pfc-register-form-col {
            order: 1;

            &::before {
                left: initial;
                right: 0;
                box-shadow: 2px 0 12px 0px rgba(#000, 0.2);
            }
            .col-inner .form-container {
                margin-left: 0;
                margin-right: 40px;
            }
        }
        .register-bg-container {
            .image-bg {
                order: 2;
            }
            .text-bg {
                order: 1;
            }
        }
    }

    .register-bg-container {
        top: 0;
        bottom: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
        background-color: #fff;
        .register-bg-col {
            &.image-bg {
                @media (min-width: $lg) {
                    background-image: url(../img/blue-bg-1.svg);
                    background-color: $pfs_cyan_light;
                }
            }
            &.text-bg {
                position: relative;
            }
        }
    }
    .register-content-container{
        position: relative;
        z-index: 2;
    }
    .in-form {
        .register-bg-col {
            // &.image-bg {
            //     @media (min-width: $lg) {
            //         background-image: url(../img/red-bg-1.svg);
            //     }
            // }
            &.text-bg {
                position: relative;
            }
        }
        .pfc-register-text-col {
            .text-container {
                display: none;
            }
        }
    }
}
.pfc-register-text-col {
    display: none;
    @media (min-width: $lg) {
        display: block;
    }
    .col-inner {
        display: flex;
        align-items: center;
        .text-container {
            color: #fff;
            .text-header {
                h1 {
                    margin-top: 0;
                }
            }
            .text-body {
                padding-left: 30px;
                @media (min-width: $md) {
                    padding-left: 50px;
                }
            }
        }
    }
}
.pfc-register-form-col {
    background-color: transparent;
    position: relative;
    z-index: 5;
    @media (min-width: $lg) {
        background-color: #fff;
    }
    &::before {
        display: none;
        content: "";
        position: absolute;
        width: 50px;
        top: 0;
        left: 0;
        bottom: 0;
        box-shadow: -2px 0 12px 0px rgba(#000, 0.2);
        z-index: -2;
        @media (min-width: $lg) {
            display: block;
        }
    }

    .col-inner {
        background-color: transparent;
        @media (min-width: $lg) {
            background-color: #fff;
        }
        display: flex;
        align-items: center;
        .register-form {
            @media (min-width: $lg) {
                max-width: 256px;
            }
            .forgot-pass-box {
                margin-bottom: 20px;
                a {
                    display: block;
                    text-align: right;
                    color: $pfs_primary;
                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }
        .form-container {
            position: relative;
            padding: 30px 0;
            @media (min-width: $md) {
                margin-left: 40px;
            }
            .form-step {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                background-color: #fff;
                padding-top: 36px;
                @media (min-width: $lg) {
                    // position: absolute;

                }

            }
            .form-navigation {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
                @media (min-width: $lg) {
                    position: absolute;
                    top: 30px;
                    left: 0;
                    width: 100%;
                    margin-bottom: 0;
                    z-index: 5;
                }
                .v-btn--icon {
                    font-size: 30px;
                }
            }
            .form-pagination {
                display: flex;
                justify-content: flex-end;
                @media (min-width: $lg) {
                    margin-bottom: 80px;
                }
            }
            .form-header {
                h1 {
                    margin-top: 0;
                }
            }
            .form-body {
                padding-left: 30px;
                @media (min-width: $md) {
                    padding-left: 100px;
                }
                form {
                    max-width: 256px;
                }
                ul {
                    margin-bottom: 30px;
                    @media (min-width: $md) {
                        margin-bottom: 50px;
                    }
                    li {
                        &::before {
                            content: "\e910";
                            font-family: "icomoon";
                            font-size: 10px;
                            line-height: 28px;
                            margin-right: 8px;
                        }
                    }
                }

                .mobil-login-btn {
                    display: flex;
                    @media (min-width: $lg) {
                        display: none;
                    }
                }
            }
        }
    }
}

.pfc-register-form-col .col-inner .form-container {
    width: 100%;
}
.register-form-slider {
    height: 100%;
    overflow: hidden;
    position: relative;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
    transition: all .25s ease-out;
    position: absolute;
}

.slide-left-enter {
    transform: translateX(100%);
}

.slide-left-leave-to {
    transform: translateX(-100%);
}

.slide-right-enter {
    transform: translateX(-100%);
}

.slide-right-leave-to {
    transform: translateX(100%);
    // position: absolute;
}



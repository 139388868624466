@use "../utils/variables"  as *;

.pfs-card-search {
    margin-bottom: 25px;
    position: relative;
    background-color: $pfs_grey_lighter;
    padding: 20px;
    text-align: center;
    transition: transform 0.2s linear;
    @media (min-width: $xxl) {
        max-width: 1096px;
        text-align: left;
    }
    &:hover,
    &:focus {
        transform: translateY(-10px);
        .card__hover {
            opacity: 0.1;
            transform: scaleY(1);
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
    .card__hover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;
        transition-property: opacity, transform;
        transition-duration: 0.3s;
        transition-timing-function: ease;
    }
    .card-link {
        display: block;
        @media (min-width: $lg) {
            display: flex;
            align-items: center;
            padding-right: 284px;
        }
    }
    .card-text {
        text-align: left;
        h5 {
            margin-bottom: 10px;
        }
    }
    .card-excerpt {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        p:last-child {
            margin-bottom: 0;
        }
    }
    .card-btn {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        @media (min-width: $lg) {
            display: block;
        }
    }
    .card-image {
        flex: 0 0 100%;
        text-align: center;
        display: block;
        max-width: 200px;
        display: inline-block;
        @media (min-width: $lg) {
            margin-right: 24px;
        }
        img {
            display: inline;
            max-width: 100%;
            max-height: 200px;
            height: auto;
            width: auto;
        }
    }
}

@use "../utils/variables" as *;

.pfs-simple-text {
    .simple-text-header {
        padding-top: 30px;
        @media (min-width: $lg) {
            padding-top: 175px;
        }
        .pfs-breadcrumbs {
            margin-bottom: 30px;
            padding: 0 28px;
            @media (min-width: $lg) {
                margin-bottom: 60px;
                padding: 0;
            }
        }
        h1 {
            margin-bottom: 20px;
            @media (min-width: $lg) {
                margin-bottom: 50px;
            }
        }
    }
    .simple-text-body {
        margin-bottom: 60px;
        padding: 0 28px;
        @media (min-width: $lg) {
            margin-bottom: 75px;
            padding: 0;
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
            // padding-top: 35px;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 0.5em;
            // font-family:  $font-bold;
            font-weight: normal;
        }
        p {
            margin-bottom: 35px;
            &:last-child {
            }
        }
        ol,
        ul {
            padding-left: 30px;
            margin-bottom: 35px;
        }
        ul {
            list-style: disc;
        }
        blockquote {
            opacity: 0.6;
        }
    }
}

@use "../utils/variables" as *;


.product-detail-slider-sec {
    .product-detail-slider {
        padding-top: 30px;
        // display: grid;
        // grid-template-columns: 58px auto;

        display: flex;

        .nav-container {
            display: none;
            flex: 1 1 58px;
           
        }
        @media (min-width: $lg) {
            padding-top: 90px;
        }
        .slick-arrow.slick-next {
            position: static;
            display: inline-block;
            transform: rotate(90deg);
        }
    }

    .slider-main {
        width: 200px;
        margin: 0 auto;
        @media (min-width: $lg) {
            width: 100%;
        }
    }

    .main-container {
        width: 100%;
    }

    .nav-container {
        flex: 1 1 58px;
        text-align: center;
        @media (min-width: $lg) {
            height: 480px;
            flex: 1 1 90px;
        }

        .slider-nav.slick-initialized.slick-slider.slick-vertical {
            height: 280px !important;
            @media (min-width: $lg) {
                height: 400px !important;
            }
        }
        img {
            border: 2px solid $pfs_grey_middle;
            padding: 15px 0;
            // width: 58px;
            // height: 58px;
            @media (min-width: $lg) {
                // width: 90px;
                // height: 90px;
            }
        }
        .slick-current {
            img {
                // border-width: 4px;
            }
        }
        .slick-slide {
            max-height: 90px;
            margin-bottom: 10px;
        }
    }
   

    .slider-nav.slick-initialized.slick-slider.slick-vertical {
        display: flex !important;
        flex-direction: column-reverse;
        text-align: center;
        height: 100%;
        justify-content: center;
    }
}

.load-more-items {
    margin-bottom: 36px;
    text-align: center;

    .progress-bar {
        width: 250px;
        height: 3px;
        margin: 5px auto 20px;
        display: block;
        // background-color: variables.$pfs_cyan_light;
    }
}
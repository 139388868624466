// @font-face {
//     // font-family:  "Gotham Medium";
//     src: url("../fonts/Gotham-Medium.ttf") format("truetype"),
//     url("../fonts/Gotham-Medium.woff") format("woff"),
//     url("../fonts/Gotham-Medium.woff2") format("woff2");
//     // font-display: swap;
// }

// @font-face {
//     // font-family:  "Gotham Black";
//     src: url("../fonts/Gotham-Black.ttf") format("truetype"),
//     url("../fonts/Gotham-Black.woff") format("woff"),
//     url("../fonts/Gotham-Black.woff2") format("woff2");
//     // font-display: swap;
// }

// @font-face {
//     // font-family:  "Gotham Light";
//     src: url("../fonts/Gotham-Light.ttf") format("truetype"),
//     url("../fonts/Gotham-Light.woff") format("woff"),
//     url("../fonts/Gotham-Light.woff2") format("woff2");
//     // font-display: swap;
// }


  /* montserrat-regular - latin-ext_latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/montserrat-v15-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v15-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v15-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v15-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v15-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v15-latin-ext_latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-500 - latin-ext_latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/montserrat-v15-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v15-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v15-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v15-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v15-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v15-latin-ext_latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-600 - latin-ext_latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/montserrat-v15-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v15-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v15-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v15-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v15-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v15-latin-ext_latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-700 - latin-ext_latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/montserrat-v15-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v15-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v15-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v15-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v15-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v15-latin-ext_latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-800 - latin-ext_latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/montserrat-v15-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v15-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v15-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v15-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v15-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v15-latin-ext_latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
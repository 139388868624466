@use "../utils/variables" as *;

.secondary-contact-form {
    .headline {
        margin-bottom: 1.2rem;
        text-transform: uppercase;
    }
    .sub-headline {
        margin-bottom: 2rem;
    }

    &__col-left {
        background-color: $pfs_cyan_light;
        position: relative;
        color: white;
        padding: 50px 30px 170px;
        justify-content: flex-start !important;
        margin-bottom: 2rem!important;

        @media(min-width: $md) {
            padding: 60px 50px 230px;
            margin-bottom: 0!important;
        }

        .contact-info {
            > p {
                margin-bottom: .6rem;
            }
        }

        &::after {
            content: "";
            position: absolute;
            background-image: url("../img/contact-form-deco.png");
            bottom: 0;
            left: 0;
            width: 100%;
            height: 200px;
            background-size: 80% 100%;

            @media(min-width: $md) {
                background-size: 90% 100%;
                height: 50%;
                max-height: 500px;
            }
        }
    }
    &__col-right {
        @media(min-width: $md) {
            padding: 60px 0 0 30px;
        }

        .v-input {
            &--checkbox {
                margin-top: 0;
            }
        }
    }
}
@use "../utils/variables" as *;

.recipe-detail-tabs-nav {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    @media (min-width: $lg) {
        display: none;
    }
    .nav-btn {
        padding: 5px 20px;
        font-size: 14px;
        line-height: 24px;
        background-color: $pfs_grey_light;
        &.is-active {
            background-color: $pfs_primary;
            color: #fff;
        }
    }
    ul {
        display: inline-flex;
        align-items: center;
        li {
        }
    }
}
.recipe-detail-tabs {
    @media (min-width: $lg) {
        // display: grid;
        // grid-template-columns: 400px auto;
        display: flex;
    }
    margin-bottom: 50px;
}

.recipe-detail-tab {
    &:first-child {
        flex: 0 0 400px;
    }
    &:last-child {
        flex: 1;
    }
    .recipe-detail-tabs-title {
        text-transform: uppercase;
        margin-bottom: 15px;
        &.tab-title {
            margin-bottom: 20px;
            display: none;
            @media (min-width: $lg) {
                display: block;
            }
        }
    }
}
.ingredients-tab {
    .serves-counter-box {
        grid-area: serves-counter-box;
    }
    .ingredient-list {
        grid-area: ingredient-list;
    }
    .product-box {
        grid-area: product-box;
        .pfs-card--product{
            height: auto;
            width: 100%;
            max-width: 500px;
        }
    }
    display: block;
    @media (min-width: $sm) {
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        // grid-template-rows: auto auto;
        // gap: 0px 0px;
        // grid-template-areas:
        //     "serves-counter-box serves-counter-box"
        //     "ingredient-list product-box";
    }
    @media (min-width: $lg) {
        display: block;
        // text-align: left;
    }
    .ingredient-list {
        margin-bottom: 50px;
        ul {
            text-align: left;
            display: inline-block;
            li {
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .ingredient-list,
    .product-box {
        text-align: left;
        @media (min-width: $sm) {
            text-align: center;
        }
        @media (min-width: $lg) {
            text-align: left;
        }
        .pfs-card {
            text-align: left;
        }
    }
}
.preperation-tab {
    @media (min-width: $md) {
        padding: 0 50px;
    }
    .step {
        margin-bottom: 40px;
        @media (min-width: $lg) {
            margin-bottom: 50px;
            display: flex;
        }
        .step-count {
            flex: 0 0 37px;
            width: 37px;
            height: 37px;
            text-align: center;
            display: inline-block;
            font-size: 40px;
            line-height: 1;
            position: relative;
            // font-family:  $font-bold;

            margin-right: 40px;
            margin-bottom: 35px;
            &::after {
                content: "";
                position: absolute;
                background-color: $pfs_red_dark;
                width: 37px;
                height: 3px;
                left: 0;
                bottom: -18px;
                @media (min-width: $lg) {
                    width: 3px;
                    height: 37px;
                    left: initial;
                    bottom: initial;
                    right: -18px;
                    top: 3px;
                }
            }
        }
        .step-text {
            ul{
                list-style: disc;
                padding-left: 30px;
            }
            li{
                padding-left: 10px;
            }
        }
        &.pro-tip {
            padding: 35px 60px 35px 20px;
            background-color: $pfs_red_dark;
            color: #fff;
            margin: auto -28px 40px -28px;
            text-align: center;
            @media (min-width: $md) {
                margin: 0 0 40px 0;
                text-align: left;
            }
            @media (min-width: $lg) {
                margin: 0 0 50px 0;
            }
            .step-count {
                margin-right: 0;
                height: auto;
                width: 40px;
                @media (min-width: $md) {
                    margin-right: 20px;
                }
                @media (min-width: $lg) {
                    width: 24px;
                    flex: 0 0 24px;
                }
                &::after {
                    display: none;
                }
                img {
                    width: 100%;
                }
            }
            .step-text {
                text-align: left;
                h3 {
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
.serves-counter-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    @media (min-width: $lg) {
        justify-content: flex-start;
    }
    .title {
        color: $pfs_grey_middle;
        margin-right: 25px;
        @media (min-width: $lg) {
        }
        // font-size: ;
    }
    .serves-counter {
        display: flex;
        align-items: center;
        justify-content: center;
        .counter-val {
            padding: 0 10px;
        }
        .counter-btn {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            box-shadow: none;
            transition: background-color 0.3s ease-in-out;
            &:hover,
            &:focus {
                background-color: $pfs_red_dark;
                path {
                    fill: #fff;
                }
            }
            .minus-mark {
                width: 15px;
                height: 3px;
            }
            .plus-mark {
                width: 15px;
                height: 15px;
            }
            path {
                fill: $pfs_red_dark;
            }
        }
    }
}

.recipe-detail-tab {
    display: none;

    &.is-active {
        display: block;
    }
    @media (min-width: $lg) {
        display: block;
    }
}

@use "../utils/variables" as *;

.pfs-card-secondary {
    width: 100%;
    position: relative;
    transition: transform 0.3s ease;
    display: inline-block;
    border-radius: 2px;
    height: 100%;
    margin-bottom: 20px;
    @media (min-width: $md) {
        min-height: 300px;
        margin-bottom: 0;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-height: 330px;

        a.card-link {
            .card-image {
                top: initial !important;
            }
        }
        &--type-saved-menu {
            .card-link {
                display: block;
                .card-title {
                    height: 44px;
                    overflow: hidden;
                }
            }
        }
        &--type-image-download {
            .card-image {
                img {
                    width: 100%;
                }
            }
            .pfs-btn-primary {
                background-color: rgba(0, 0, 0, 0);
            }
        }
    }
	.card-platform-select-box{
		height: 44px;
		.v-text-field{
			margin-top: 0;
		}
	}
    .card-link {
        background-color: $pfs_grey_lighter;
        padding: 0 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 2;
        justify-content: flex-start;
        align-content: flex-start;
        .card-text,
        .card-info,
        .card-action,
        .card-title {
            @media (min-width: $md) {
                width: 100%;
            }
        }
    }
    a.card-link {
        cursor: pointer;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            opacity: 0;
            transform: scaleY(0);
            transform-origin: top;
            transition-property: opacity, transform;
            transition-duration: 0.3s;
            transition-timing-function: ease;
            border-radius: 2px;
            z-index: -1;
            background-color: $pfs_red;
        }
        &:hover {
            &::after {
                opacity: 1;
                transform: scaleY(1);
            }
        }
    }
    .top-bar {
        text-align: right;
        background-color: #fff;
        position: relative;
        @media (min-width: $md) {
            background-color: $pfs_grey_lighter;
        }
        .card-btn {
            width: 45px;
            height: 45px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            font-size: 32px;
            &:hover,
            &:focus {
                background-color: $pfs_grey_light;

                .btn-icon {
                    color: $pfs_primary;
                }
            }
            .btn-icon {
                pointer-events: none;
                color: $pfs_black;
            }
        }
        .card-dropdown {
            position: absolute;
            z-index: 20;
            right: 0;
            top: 45px;
            display: none;
            background-color: #fff;
            padding: 5px 0;
            box-shadow: 9px 4px 11px rgba(0, 0, 0, 0.15);
            &.active {
                display: inline-block;
            }
            .card-dropdown-item {
                text-align: left;

                padding: 5px 20px;
                min-width: 160px;
                display: block;
                transition: all 0.15s ease-out;
                &:hover,
                &:focus {
                    background-color: $pfs_primary;
                    color: #fff;
                }
            }
        }
    }
    .card-title {
        margin-top: 0;
        margin-left: 110px;
        min-height: 50px;
        padding-top: 15px;
        margin-bottom: 5px;
        @media (min-width: $md) {
            height: 62px;
            overflow: hidden;
            margin-left: 0;
            padding-top: 20px;
            min-height: auto;
        }
    }
    .card-text {
        padding-top: 10px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 24px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
    .card-info {
        margin-bottom: 20px;
        display: flex;
        .info-item {
            margin-right: 45px;
            &:last-child {
                margin-right: 0;
            }
            .info-icon {
                margin-right: 10px;
            }
        }
    }
    .card-image {
        position: absolute;
        width: 100px;
        height: 100px;
        left: 10px;
        top: -50px;
        background-position: center;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;

        @media (min-width: $md) {
            display: block;
            margin: 0 auto;
            margin-bottom: 20px;
            position: static;
            width: calc(100% - 60px);
            height: auto;
            top: initial;
            left: initial;
        }
    }
    .card-action {
        border-top: 1px solid $pfs_grey_middle;
        padding-top: 15px;
        .sizes {
            display: flex;
            margin-bottom: 5px;
            label {
                margin-right: 30px;
            }
        }
        .pfs-btn {
            margin-left: -10px;
        }
    }
    &--is-link {
        &:hover,
        &:focus {
            @media (min-width: $md) {
                transform: translateY(-10px);
                transition: transform 0.2s linear;
            }

            .card__hover {
                opacity: 1;
                transform: scaleY(1);
            }
            a.card-link {
                color: #fff;
            }
        }
    }

    &--has-top-bar {
        .card-link {
            &::after {
                display: none;
            }
        }
        .card-title {
            @media (min-width: $md) {
                height: 46px;
                padding-top: 0;
            }
        }
        .card-image {
            top: -50px;
        }
        .card__hover {
            display: none;
        }
        &:hover,
        &:focus {
            a.card-link {
                color: $pfs_black;
            }
        }
    }
    &--short-image {
        .card-link {
            height: auto;
            @media (min-width: $md) {
                height: calc(100% - 45px);
            }
        }
        .card-image {
            @media (min-width: $md) {
                margin-bottom: 0;
                padding-top: 0;
                overflow: hidden;
                height: 180px;
                width: 230px;
                position: relative;
                img {
                    position: absolute;
                    bottom: -55px;
                }
            }
            @media (min-width: $xl) {
                width: calc(100% - 60px);
                height: 180px;
            }
            @media (min-width: $xl) {
                height: 160px;
            }
            @media (min-width: $hd) {
                height: 140px;
            }
        }
    }

    // CArd types
    &--type-teaser-recipe {
        background-color: green;
        a.card-link {
            height: 100%;
        }

        .card-info {
            // margin-bottom: auto;
        }
    }
    &--type-saved-recipe {
        .card-link {
            height: auto;
            @media (min-width: $sm) {
                height: calc(100% - 45px);
            }
        }
        .card-text {
            @media (min-width: $sm) {
                margin-bottom: 10px;
            }
        }
    }
    &--type-saved-menu {
        .card-link {
            height: auto;

            @media (min-width: $sm) {
                height: calc(100% - 45px);
            }
            
        }
        .card-title {
            margin-bottom: 15px;
        }
        .card-text{
            padding-top:0 ;
        }
        .card-image {
            @media (min-width: $md) {
                flex: 1 1;
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }
            img {
                max-height: 100px;
                @media (min-width: $md) {
                    max-height: 200px;
                }
            }
        }
    }
    &--type-image-download {
        .card-link {
            height: auto;
            @media (min-width: $sm) {
                height: 100%;
            }
        }
        .card-title {
            @media (min-width: $md) {
                margin-bottom: 15px;
            }
        }
        .card-image {
            img {
                max-height: 100px;
            }
            @media (min-width: $md) {
                flex: 1 1;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-height: 200px;
                }
            }
        }
    }
}

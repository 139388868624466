@use "../utils/variables" as *;

.pfs-search-results {
    .results-header {
        padding-top: 25px;
        margin-bottom: 85px;
        @media (min-width: $xl) {
            padding-top: 65px;
        }
    }
    .results-list{
        margin-bottom: 25px;
        @media (min-width: $xl){
            margin-bottom: 65px;
        }
    }
    .load-more-items{
        margin-top: 0;
        margin-bottom: 70px;
        @media (min-width: $xl){
            margin-bottom: 65px;
        }
    }
}

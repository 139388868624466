.helper-menu {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    .helper-menu-btn {
        border: none;
        box-shadow: none;
        display: block;
        width: 50px;
        height: 50px;
        background-color: #222;
        color: #fff;
        padding-left: 9px;
        line-height: 1;
        font-size: 20px;
        font-weight: 700;
        border-radius: 0 0 50px 0;
        text-align: left;
        padding-bottom: 10px;
    }
    &.open {
        ul {
            display: inline-block;
        }
    }
    ul {
        position: absolute;
        left: 10px;
        top: 55px;
        display: none;
        background-color: #222;
        padding: 10px 0;
        border-radius: 5px;
        li {
            a {
                white-space: nowrap;
                display: block;
                padding: 5px 20px;
                font-size: 14px;
                font-weight: 400;
                color: #fff;
                &:hover {
                    background-color: #fff;
                    color: #222;
                }
            }
        }
    }
}

@use "../utils/variables" as *;

.pfs-breadcrumbs {
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    margin-top: 10px;
    padding-bottom: 15px;
    overflow-y: scroll;

    // Hide scrollbar
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    ul {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        li {
            color: $pfs_black;
            position: relative;
            display: flex;
            align-items: center;
            &::after {
                content: "\e914";
                // padding: 0 8px;
                display: inline-block;
                padding: 0 10px;
                font-family:  'icomoon' !important;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                font-size: 12px;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
            a,
            span {
                white-space: nowrap;
            }
            a {
                display: inline-block;
                color: $pfs_grey_middle;
            }
            span {
                display: inline-block;
            }
        }
    }
}



@use "../utils/variables" as *;

.v-application {
    &--wrap {
        min-height: inherit;
    }
}

.v-application {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    min-height: 100%;
}
.theme--light.v-application{
    // background-color: transparent;
    // background-color:rgba(0, 0, 0, 0);
    
}
.v-btn {
    // border-radius: 0;
    // box-shadow: none;
    font-size: 18px;
    @media (min-width: 340px) {
        min-width: 253px;
        min-width: 254px !important;
    }
    &.v-btn--icon {
        min-width: auto !important;
    }
}
.v-application {
    .title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
    }
    a.pfs_primary-link {
        color: $pfs_primary;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    .v-btn--icon.v-size--default {
        height: 36px;
        width: 36px;
    }
}
.full-height-app {
    // min-height: calc(100vh - 80px - 65px);
    @media (min-width: $md) {
        min-height: calc(100vh - 80px - 60px);
    }
    @media (min-width: $xl) {
        min-height: calc(100vh - 100px - 60px);
        // width: 120px;
        // height: 120px;
    }
}

.v-btn.v-size--x-large {
    font-size: 18px;
}
.theme--light.v-card > .v-card__text {
    color: $pfs_black;
    h3 {
        font-weight: 900;
    }
}
.v-toolbar__content {
    .v-btn__content {
        font-size: 30px;
    }
}
.v-card__text {
    p:last-child {
        margin-bottom: 0;
    }
}
.v-dialog > .v-card > .v-card__actions{
    // background-color: red;
    padding-bottom: 24px;
}

[v-cloak] > * { display:none }
// [v-cloak]::before { content: "loading…" }

.label-with-mask-bg{
    label{
        background-color: #fff;
    }
}

.test_cursor input {
    cursor: pointer;
  }
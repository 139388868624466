@use "../utils/variables";

.pfs-login {
    .v-application {
        height: 100%;
    }

    @keyframes show {
        0%,
        49.99% {
            opacity: 0;
            z-index: 1;
        }

        50%,
        100% {
            opacity: 1;
            z-index: 5;
        }
    }

    .content-wrapper {
        background-color: #ffffff;
        display: flex;
        align-content: flex-start;
        justify-content: center;
        flex-direction: column;

        height: 100%;
        text-align: left;
        margin-left: 10%;
        .content-wrapper-inner {
            padding-right: 10px;
            overflow-y: scroll;
            padding-top: 30px;
            padding-bottom: 30px;
            @media screen and (max-width: variables.$sm) {
                overflow-y: scroll;
                padding-top: 30px;
                padding-bottom: 30px;
               
            }
            @media screen and (min-height: 700px){
                padding-top: 0x;
                padding-bottom: 0;
                overflow-y: visible;
            }
            .content-body {
                padding: 0 50px;
                padding-bottom: 50px;
            }
        }
    }
    .container {
        background-color: #fff;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
        position: relative;
        overflow: hidden;
        max-width: 100%;
        // min-height: 480px;
        height: 100%;
    }
    .form-container {
        position: absolute;
        top: 0;
        height: 100%;
        transition: all 0.6s ease-in-out;

        &.sign-in-container {
            left: 0;
            width: 100%;
            z-index: 2;

            @media (min-width: variables.$lg) {
                width: 50%;
            }

            .login-form-content {
                max-width: 256px;

                .v-input {
                }

                .forgot-password-link {
                    color: variables.$pfs_primary;
                    width: 100%;
                    text-align: right;
                    display: block;
                    margin-bottom: 18px;
                }
            }
        }

        &.sign-up-container {
            left: 0;
            width: 100%;
            opacity: 1;
            z-index: 1;

            @media (min-width: variables.$lg) {
                opacity: 0;
                width: 50%;
            }

            p {
                margin-bottom: 36px;
            }

            ul {
                padding-left: 0;
                margin-bottom: 36px;
                li {
                    margin: 18px 0;
                    position: relative;

                    &::before {
                        content: "\e910";
                        font-family: "icomoon";
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    .container.right-panel-active {
        .sign-in-container {
            transform: translateX(100%);
        }
        .sign-up-container {
            transform: translateX(100%);
            opacity: 1;
            z-index: 5;
            animation: show 0.6s;
        }
        .pfs-login-overlay-container {
            transform: translateX(-100%);
        }
        .overlay {
            transform: translateX(50%);
        }
        .overlay-left {
            transform: translateX(0);
        }
        .overlay-right {
            transform: translateX(20%);
        }
    }

    .pfs-login-overlay-container {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;
        overflow: hidden;
        transition: transform 0.6s ease-in-out;
        z-index: 5;
        display: none;

        @media (min-width: variables.$lg) {
            display: block;
        }
    }
    .overlay {
        background-color: variables.$pfs_cyan_light;
        background-image: url("../img/coming-soon-bg-pattern.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        color: variables.$pfs_white;
        position: relative;
        left: -100%;
        height: 100%;
        width: 200%;
        transform: translateX(0);
        transition: transform 0.6s ease-in-out;
    }
    .overlay-panel {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 40px;
        text-align: center;
        top: 0;
        height: 100%;
        width: 50%;
        transform: translateX(0);
        transition: transform 0.6s ease-in-out;
    }
    .overlay-left {
        transform: translateX(-20%);
    }
    .overlay-right {
        right: 0;
        transform: translateX(0);
    }
}

@use "../utils/variables";

.pfs-featured-slider {
    padding: 20px 0 58px 0;
    margin-bottom: 36px;

    @media (min-width: variables.$md) {
        padding-bottom: 20px;
    }

    .fs-paginator {
        position: absolute;
        font-size: 18px;
        bottom: -40px;
        right: 28px;
        left: inherit;
        top: inherit;
        color: variables.$pfs_grey_middle;

        @media (min-width: variables.$md) {
            top: 30px;
            left: -80px;
            right: inherit;
            bottom: inherit;
            font-size: 24px;
        }

        .fsp-curent {
            color: variables.$pfs_black;
        }
    }

    .image-slider {
        .slick-prev {
            top: initial;
            right: initial;
            left: -158px;
            bottom: 10px;
        }

        .slick-next {
            top: initial;
            right: initial;
            left: -80px;
            bottom: 10px;
        }

        .slick-list {
            padding: 10px 20% 10px 0;
            .slick-slide {
                width: 300px;
                margin: 0 10px;
            }
        }
    }

    .content-wrapper {
        .col-content {
            margin-bottom: 36px;
            p{
                @media (min-width: variables.$md){
                    margin-right: 150px;

                }
            }
        }
    }

    .pfs-card {
        &.pfs-card--recipe {
            padding-bottom: 0;
        }
        h3 {
            overflow: hidden;
            height: 56px;
            @media (min-width: variables.$md) {
            }
            & + p {
                overflow: hidden;
                height: 70px;
            }
        }
        .additional-info {
            display: flex;
            .prep-time {
                margin-right: 36px;
                &::before {
                    content: "\e907";
                    display: inline-block;
                    font-family: icomoon;
                    margin-right: 2px;
                    margin-bottom: -3px;
                }
            }
            .portion-cost {
                margin-right: 36px;
                &::before {
                    content: "\e908";
                    display: inline-block;
                    font-family: icomoon;
                    margin-right: 2px;
                    margin-bottom: -3px;
                }
            }
        }
        .pfs-card-recipe-image {
            padding-top: 100%;
            position: relative;
            img.recipe-image {
                // position: static;
                // left: 0;
                // transform: none;
            }
        }
    }
}

@use "../utils/variables";

.pfs-card-box {
    padding-top: 20px;
}
.pfs-card {
    background-color: variables.$pfs_grey_lighter;
    padding: 16px;
    // max-width: 300px;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease;
    display: inline-block;
    border-radius: 2px;

    &:hover {
        color: variables.$pfs_white;
        transform: translateY(-10px);
        transition: transform 0.2s linear;

        .card__hover {
            opacity: 1;
            transform: scaleY(1);
        }
    }

    &--brand {
        h3 {
            text-transform: uppercase;
        }
        p {
            margin: 10px auto 40px;
        }
        img {
            max-height: 118px;
            margin: 0 auto;
        }
    }

    &--recipe {
        padding-bottom: 170px;

        > p {
            margin: 5px 0 10px;
        }

        img.recipe-image {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .additional-info {
            .prep-time {
                margin-right: 40px;
            }
            .prep-time,
            .portion-cost {
                display: inline-block;
                height: 30px;

                > * {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }

        &:hover {
            .additional-info {
                .prep-time,
                .portion-cost {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }

    &--product {
        height: 100%;
        img {
            display: block;
            margin: 0 auto 16px;
            max-width: 200px;
        }
        // h5 {
        //     margin: 25px 0 15px;
        // }
        ul {
            list-style-type: disc;
            padding-left: 1rem;
            li {
                padding: 5px 0;
            }
        }
    }

    &--featured {
        padding: 0;
        -webkit-box-shadow: 2px 2px 2px 0px #848484;
        box-shadow: 2px 2px 2px 0px #848484;

        &:hover {
            color: variables.$pfs_black;
        }        

        .cf-image-container {
            width: 300px;
            height: 300px;
            background-position: center center;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
        .cf-content-wrapper {
            padding: 0 20px 20px 20px;

            p {
                margin-bottom: 18px;
            }

            a {
                color: variables.$pfs_primary;
                text-transform: uppercase;
            }
        }
    }
    
   

    .card__hover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: top;
        transform-origin: top;
        transition-property: opacity, -webkit-transform;
        transition-property: opacity, transform;
        transition-property: opacity, transform, -webkit-transform;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        border-radius: 2px;
    }
}

.pfs-card--brand{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@use "../utils/variables" as *;

.pfs-radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  
    cursor: pointer;
    position: relative;
    input {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
    &:hover,&:focus{
        color: $pfs_primary;
    }
    .design {
        width: 24px;
        height: 24px;
        border: 2px solid $pfs_black;
        border-radius: 100%;
        margin-right: 16px;

        position: relative;
    }

    .design::before,
    .design::after {
        content: "";
        display: block;

        width: inherit;
        height: inherit;

        border-radius: inherit;

        position: absolute;
        transform: scale(0);
        transform-origin: center center;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
    }

    .design:before {
        background: $pfs_primary;
        opacity: 0;
        transition: 0.3s;
    }

    .design::after {
        background: $pfs_primary;
        opacity: 0.4;
        transition: 0.6s;
    }
    .text {
       font-size: 14px;
    }

    /* checked state */
    input:checked + .design {
        border-color: $pfs_primary;
    }
    input:checked + .design::before {
        opacity: 1;
        transform: scale(0.6);
    }

    /* other states */
    input:hover + .design,
    input:focus + .design {
        border: 2px solid $pfs_primary;
    }

    input:hover + .design:before,
    input:focus + .design:before {
        background: $pfs_primary;
    }

    input:hover ~ .text {
        color: $pfs_primary;
    }

    input:focus + .design::after,
    input:active + .design::after {
        opacity: 0.1;
        transform: scale(2.6);
    }
}


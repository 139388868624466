.pfs-contest {
    .contest-form-wrapper {
        max-width: 540px;        
        margin: 5% auto 36px;        
    }
    .contest-response {
        margin-bottom: 72px;
        max-width: 620px;

        h2 {
            margin-bottom: 36px
        }
    }
}
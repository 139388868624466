@use "../utils/variables";

.pfs-news-article-main {
    background-position: right;    
    margin-bottom: 36px;   
    margin-left: 28px;
    margin-right: 28px; 
    background-size: 100%;
    background-position: top center;

    @media(min-width: variables.$md) {
        background-size: 80%;        
        background-position: center right;
        margin-left: 0;
        margin-right: 0;
    }

    @media(min-width: variables.$lg) {
        background-size: inherit;
    }    

    .nam-content {
        margin-top: 60%;        
        padding: 15px;
        background-color: variables.$pfs_white;    

        @media(min-width: variables.$md) {
            margin: 20px 0;
            padding: 30px 40px 30px 0;
        }

        @media(min-width: variables.$lg) {
            margin: 90px 0;            
        }
    }

    // overriding global rule in component... if there's better option, refactor this solution
    &.pfs-row {
        .col-content {
            padding: 0;
        }
    }
}
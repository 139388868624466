@use "../utils/variables";

.coming-soon {
    color: variables.$pfs_white;
    background-color: variables.$pfs_cyan_light;
    background-image: url("../img/coming-soon-bg-pattern.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;

    p {
        padding-left: 15%;
    }
}
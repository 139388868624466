@use "../utils/variables";

.pfs-home {
    position: relative;

    .slider-content {
        position: absolute;
        width: 100%;
        min-height: 100%;
        display: flex;
        align-items: center;

        .slider-paginator {
            position: absolute;
            top: 140px;
            right: 15%;
            font-size: 24px;
            color: variables.$pfs_white;
            font-weight: bold;
        }

        #Slider_FG {
            // z-index: 1;
            width: 100%;
            height: 100%;
            background-color: transparent;
            position: relative;
            color: variables.$pfs_white;

            .slider-page {
                .image-wrapper {
                    position: relative;
                    max-width: 50%;

                    @media (min-width: variables.$xl) {
                        max-width: 100%;
                        img{
                            margin-bottom: 50px;
                        }
                    }
                }

                .content-wrapper {
                    position: relative;
                    top: 0;
                    left: 0;

                    @media (min-width: variables.$md) and (max-width: variables.$xxl) {
                        h1 {
                            font-size: 60px;
                            line-height: 64px;
                        }
                    }
                    h1{
                        @media (min-width: 768px) and (max-width : 1280px){
                            text-shadow: 1px 1px 10px rgb(0 0 0 / 50%);
                        }
                    }

                    @media (min-width: variables.$md) {
                        position: absolute;
                        top: 40px;
                        left: 25%;
                    }

                    @media (min-width: variables.$lg) {
                        left: 40%;
                    }
                    .content-wrapper-inner {
                        @media screen and (min-width: variables.$sm) and (max-height: 700px) {
                            overflow-y: auto;
                            height: calc(100vh - 205px);
                        }
                    }

                    .slider-description {
                        position: relative;
                        left: 0;
                        max-width: 420px;

                        @media (min-width: variables.$xl) {
                            // left: 23%;
                            max-width: 620px;
                            padding-left: 23%;
                        }

                        .sd-holder {
                            max-height: 90px;
                            overflow-y: auto;
                            margin-bottom: 10px;
                            position: relative;
                            scrollbar-color: white rgba(255, 255, 255, 0.2);
                            scrollbar-width: thin;
                            direction: rtl;
                            text-align: left;
                            padding-left: 20px;

                            @media (min-width: variables.$md) {
                                max-height: inherit;
                                direction: ltr;
                                padding-left: 0;
                            }

                            &::-webkit-scrollbar {
                                width: 6px;
                            }
                            &::-webkit-scrollbar-track {
                                -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
                            }
                            &::-webkit-scrollbar-thumb {
                                background-color: white;
                            }

                            p {
                                margin: 0;
                            }

                            // &::after {
                            //     content: "";
                            //     width: 100%;
                            //     height: 30px;
                            //     position: absolute;
                            //     bottom: 0;
                            // }

                            // &.blue {
                            //     // &::after {
                            //         border-bottom: 30px solid linear-gradient(to bottom, rgb(0, 169, 224, 0), rgb(0, 169, 224, 1));
                            //     // }
                            // }
                            // &.green {
                            //     // &::after {
                            //         border-bottom: 30px solid linear-gradient(to bottom, rgb(120, 190, 32, 0), rgb(120, 190, 32, 1));
                            //     // }
                            // }
                            // &.yellow {
                            //     // &::after {
                            //         border-bottom: 30px solid linear-gradient(to bottom, rgb(247, 205, 90, 0), rgb(247, 205, 90, 1));
                            //     // }
                            // }
                            // &.red {
                            //     // &::after {
                            //         border-bottom: 30px solid linear-gradient(to bottom, rgb(188, 39, 44, 0), rgb(188, 39, 44, 1));
                            //     // }
                            // }
                        }
                    }
                }
            }

            .slick-list {
                overflow: initial;
            }

            .slick-dots {
                // bottom: -40px;
                bottom: -160px;
            }
            .slick-dots li button {
                background: variables.$pfs_white;
                opacity: 0.5;
                height: 3px;
                padding: 3px;
            }

            .slick-dots li.slick-active button {
                opacity: 1;
            }
            .slick-dots li button::before {
                display: none;
            }
        }
        #Slider_BG {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 80px;

            @media (min-width: variables.$md) {
                top: 0;
                position: relative;
            }

            .slider-page {
                width: 100%;
                height: 100%;
                min-height: calc(100vh - 145px);
                padding: 0 100px;
                display: flex !important;
                /* justify-content: center; */
                align-items: center;

                &.red {
                    background: variables.$pfs_red;

                    .lottie {
                        position: absolute;
                        display: inline-block;
                        &.circle {
                            top: 5%;
                            left: 45%;
                        }
                        &.crossSign {
                            bottom: 5%;
                            right: 30%;
                        }
                    }
                }
                &.green {
                    background: variables.$pfs_green_light;
                    .lottie {
                        display: inline-block;
                        position: absolute;
                        &.circle {
                            top: 30px;
                            left: 50%;
                        }
                        &.crossSign {
                            bottom: 0;
                            right: 5%;
                        }
                    }
                }
                &.blue {
                    background: variables.$pfs_cyan_light;
                    .lottie {
                        position: absolute;
                        display: inline-block;
                        &.circle {
                            bottom: 10%;
                            left: 40%;
                        }
                        &.crossSign {
                            top: 10%;
                            right: 15%;
                        }
                    }
                }
                &.yellow {
                    background: variables.$pfs_yellow;
                    .lottie {
                        position: absolute;
                        display: inline-block;
                        &.circle {
                            bottom: -120px;
                            left: 10px;
                        }
                        &.crossSign {
                            top: -40px;
                            left: 15%;
                        }
                    }
                }

                .webline_blue {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .webline_red {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .webline_green {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .webline_yellow {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                }

                .circle {
                }

                // .crossSign {
                //     position: absolute;
                //     top: 10%;
                //     right: 15%;
                // }
            }

            #Ellipse_R {
                position: absolute;
                top: 30%;
                left: 50%;
            }
            #Ellipse_G {
                position: absolute;
                top: 70%;
                left: 30%;
            }
            #Ellipse_B {
                position: absolute;
                top: 45%;
                left: 50%;
            }
            #Ellipse_Y {
                position: absolute;
                top: 20%;
                left: 80%;
            }
        }
    }
}

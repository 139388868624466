@use "../utils/variables" as *;
.drag-resize-container {
	margin: 0;
	line-height: 0;
	text-align: center;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 200;
	.logo-image {
		position: relative;
		top: 0%;
		z-index: 120;
		pointer-events: none;
		img {
			width: 126px;
		}

		&--custom {
			position: absolute;
			left: auto !important;
			right: 0;
		}
	}
	.menu-text-box-dr {
		pointer-events: none;
	}
	.menu-price-box {
		display: flex;
		align-items: center;
		z-index: 110;
		transform-origin: top right;
		position: relative;
		left: 70%;
		top: 0%;

		.menu-price {
			max-width: none;
			background-color: #e62d36;
			color: #fff;
			font-weight: 400;
			line-height: 1;
			padding: 5px;
			font-weight: 700;
			white-space: nowrap;
			font-size: 2.2em;
			pointer-events: none;
            font-family: 'Montserrat';
		}
		.menu-description {
			color: #fff;
			font-size: 11px;
			line-height: 1;
			font-size: 1.1em;
			text-transform: uppercase;
			padding-left: 10px;
			.dish-name,
			.drink-name,
			.snack-name {
				font-weight: 700;
			}
		}
	}
}

.menu-preview-box {
	.drag-resize-text-box {
		z-index: 100;
		position: relative;
	}
	.drag-resize-container {
		.menu-price-box {
			margin-top: 15px;
			text-align: left;
		}
	}

	// print_a4  format
	&.print_a4 {
		.drag-resize-container {
			.logo-image {
				left: 54%;
				width: 38%;
				img {
					width: 110px;
				}
				&--custom {
					width: 110px;
				}
			}
			.drag-resize-text-box {
				left: 54%;
				width: 38%;
				.menu-text-box-dr {
					font-size: 11px;
				}
			}
			.menu-price-box {
				font-size: 11px;
				left: 54%;
				// width: 38%;
			}
		}
	}

	// menuboard_16-9 format
	&.menuboard_16-9 {
		.drag-resize-container {
			.logo-image {
				left: 67%;
				width: 28%;
				img {
					width: 70px;
				}
				&--custom {
					width: 70px;
				}
			}
			.drag-resize-text-box {
				left: 67%;
				width: 28%;
				.menu-text-box-dr {
					font-size: 6px;
				}
			}
			.menu-price-box {
				font-size: 6px;
				left: 67%;
				// width: 28%;
			}
		}
	}

	// deliveroo_hero_image_16-9 format
	&.deliveroo_hero_image_16-9 {
		.drag-resize-container {
			.logo-image {
				left: 67%;
				width: 28%;
				img {
					width: 70px;
				}

				&--custom {
					width: 70px;
				}
			}
			.drag-resize-text-box {
				left: 67%;
				width: 28%;
				.menu-text-box-dr {
					font-size: 6px;
				}
			}
			.menu-price-box {
				font-size: 6px;
				left: 67%;
				// width: 28%;
			}
		}
	}

	// deliveroo_product_image_4-3 format
	&.deliveroo_product_image_4-3 {
		.drag-resize-container {
			.logo-image {
				left: 67%;
				width: 28%;
				img {
					width: 70px;
				}
				&--custom {
					width: 70px;
				}
			}
			.drag-resize-text-box {
				left: 67%;
				width: 28%;
				.menu-text-box-dr {
					font-size: 6px;
				}
			}
			.menu-price-box {
				font-size: 6px;
				left: 67%;
				// width: 28%;
			}
		}
	}

	// just_eat_1-1 format
	&.just_eat_1-1 {
		.drag-resize-container {
			.logo-image {
				left: 54%;
				width: 38%;
				img {
					width: 110px;
				}
				&--custom {
					width: 110px;
				}
			}
			.drag-resize-text-box {
				left: 54%;
				width: 38%;
				.menu-text-box-dr {
					font-size: 8px;
				}
			}
			.menu-price-box {
				font-size: 8px;
				left: 54%;
				// width: 38%;
			}
		}
	}

	// uber_eats_6-4 format
	&.uber_eats_6-4 {
		.drag-resize-container {
			.logo-image {
				left: 67%;
				width: 28%;
				img {
					width: 70px;
				}
				&--custom {
					width: 70px;
				}
			}
			.drag-resize-text-box {
				left: 67%;
				width: 28%;
				.menu-text-box-dr {
					font-size: 7px;
				}
			}
			.menu-price-box {
				font-size: 7px;
				left: 67%;
				// width: 28%;
			}
		}
	}

	// instagram_size_4-5 format
	&.instagram_size_4-5 {
		.drag-resize-container {
			.logo-image {
				left: 67%;
				width: 28%;
				img {
					width: 70px;
				}
				&--custom {
					width: 70px;
				}
			}
			.drag-resize-text-box {
				left: 67%;
				width: 28%;
				.menu-text-box-dr {
					font-size: 6px;
				}
			}
			.menu-price-box {
				font-size: 6px;
				left: 67%;
				// width: 28%;
			}
		}
	}
	@media (min-width: 992px) {

		// print_a4  format
		&.print_a4 {
			.drag-resize-container {
				.logo-image {
					left: 57%;
					width: 37%;
					img {
						width: 156px;
					}
					&--custom {
						width: 156px;
					}
				}
				.drag-resize-text-box {
					left: 57%;
					width: 37%;
					.menu-text-box-dr {
						font-size: 22px;
					}
				}
				.menu-price-box {
					left: 57%;
					// width: 37%;
					font-size: 22px;
				}
			}
		}

		// menuboard_16-9 format
		&.menuboard_16-9 {
			.drag-resize-container {
				.logo-image {
					left: 70%;
					width: 24%;
					img {
						width: 102px;
					}
					&--custom {
						width: 102px;
					}
				}
				.drag-resize-text-box {
					left: 70%;
					width: 24%;
					.menu-text-box-dr {
						font-size: 12px;
					}
				}
				.menu-price-box {
					font-size: 12px;
					left: 70%;
					// width: 24%;
				}
			}
		}

		// deliveroo_hero_image_16-9 format
		&.deliveroo_hero_image_16-9 {
			.drag-resize-container {
				.logo-image {
					left: 70%;
					width: 24%;
					img {
						width: 102px;
					}
					&--custom {
						width: 102px;
					}
				}
				.drag-resize-text-box {
					left: 70%;
					width: 24%;
					.menu-text-box-dr {
						font-size: 12px;
					}
				}
				.menu-price-box {
					font-size: 12px;
					left: 70%;
					// width: 24%;
				}
			}
		}

		// deliveroo_product_image_4-3 format
		&.deliveroo_product_image_4-3 {
			.drag-resize-container {
				.logo-image {
					left: 70%;
					width: 24%;
					img {
						width: 102px;
					}
					&--custom {
						width: 102px;
					}
				}
				.drag-resize-text-box {
					left: 70%;
					width: 24%;
					.menu-text-box-dr {
						font-size: 14px;
					}
				}
				.menu-price-box {
					font-size: 14px;
					left: 70%;
					// width: 24%;
				}
			}
		}

		// just_eat_1-1 format
		&.just_eat_1-1 {
			.drag-resize-container {
				.logo-image {
					left: 57%;
					width: 37%;
					img {
						width: 156px;
					}
					&--custom {
						width: 156px;
					}
				}
				.drag-resize-text-box {
					left: 57%;
					width: 37%;
					.menu-text-box-dr {
						font-size: 16px;
					}
				}
				.menu-price-box {
					left: 57%;
					// width: 37%;
					font-size: 16px;
				}
			}
		}

		// uber_eats_6-4 format
		&.uber_eats_6-4 {
			.drag-resize-container {
				.logo-image {
					left: 70%;
					width: 24%;
					img {
						width: 102px;
					}
					&--custom {
						width: 102px;
					}
				}
				.drag-resize-text-box {
					left: 70%;
					width: 24%;
					.menu-text-box-dr {
						font-size: 14px;
					}
				}
				.menu-price-box {
					font-size: 14px;
					left: 70%;
					// width: 24%;
				}
			}
		}

		// instagram_size_4-5 format
		&.instagram_size_4-5 {
			.drag-resize-container {
				.logo-image {
					left: 60%;
					width: 34%;
					img {
						width: 132px;
					}
					&--custom {
						width: 132px;
					}
				}
				.drag-resize-text-box {
					left: 60%;
					width: 34%;
					.menu-text-box-dr {
						font-size: 20px;
					}
				}
				.menu-price-box {
					font-size: 20px;
					left: 60%;
					// width: 24%;
				}
			}
		}
	}
}
.ui-draggable {
	&:hover {
		cursor: move;
	}
}
.menu-preview-box .drag-resize-text-box {
	left: 70%;
	width: 25.25%;

	.menu-text-box-dr {
		font-size: 10px;
		// background-color: $pfs_primary;
        background-color: transparent;
		padding: 1px 10px;
		color: #fff;
		z-index: 10;
		margin-bottom: 12px;
		width: 100%;
		height: 100%;
		font-family: Montserrat;
		text-align: left;
		.menu-headline {
			font-weight: normal;
			// font-size: 12px;
			font-size: 1.2em;
			line-height: 1;
			margin: 10px 0;
			font-weight: 700;
		}
		.menu-text {
			// font-size: 11px;
			font-size: 1.1em;
			line-height: 1.4;
			margin: 10px 0;
			font-weight: 500;
			white-space: pre-line;
		}
	}
}

.ui-resizable-handle {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 50px;
	height: 50px;
	background-image: none;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top-left-radius: 100px;

	&:hover {
		background-color: #fff;
	}
	&::before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 50px 50px;
		border-color: transparent transparent rgba(#fff, 0.4) transparent;
		position: absolute;
		bottom: 0;
		right: 0;
	}
	&:after {
		content: "";
		display: block;
		width: 16px;
		height: 16px;
		background-position: -64px -224px;
		background-image: url("../js/jquery-ui-1.13.0/images/ui-icons_444444_256x240.png");
		position: absolute;
		bottom: 6px;
		right: 6px;
	}
}

.drag_res_test_v2-info {
	width: 140px;
	background-color: #fff;
	position: absolute;
	left: 0;
	padding: 5px 20px;
	p {
		margin-bottom: 0;
	}
}

.ui-resizable-handle {
	&.hidden {
		display: none;
	}
}

@media print {
	.ui-resizable-handle {
		display: block;
	}
}

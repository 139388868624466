.socila-share-in-article {
    display: flex;
    align-items: center;
    padding-top: 14px;
    margin-bottom: 30px;
    .socila-share-title {
        font-size: 14px;
        margin-right: 20px;
    }
}
